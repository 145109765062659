import {
  combineReducers,
  configureStore,
  Reducer,
  AnyAction,
} from '@reduxjs/toolkit';
import userSlice from './user/userSlice';
import drawerSlice from './drawer/drawerSlice';
import generalSlice from './general/generalSlice';
import walletsSlice from './wallets/walletsSlice';
import menuItemsSlice from './sidebarMenu/sideBarMenuSlice';
import notificationsSlice from './notifications/notificationsSlice';
import settingSlice from './setting/settingSlice';
import withdrawSlice, { WITHDRAW_SLICE_NAME } from './wallets/withdrawSlice';
import depositSlice, { DEPOSIT_SLICE_NAME } from './wallets/depositSlice';
import swapSlice, { SWAP_SLICE_NAME } from './wallets/swapSlice';
import walletDataSlice, {
  WALLETDATA_SLICE_NAME,
} from './walletData/walletDataSlice';
import clouldk2Slice, { CLOULDK2_SLICE_NAME } from './Cloudk2/clouldk2Slice';
import platformSlice from './platform/platformSlice';
import rewardsSlice, {
  REWARD_SLICE_NAME,
} from './Cloudk2/rewardCalculatorSlice';
// REDUX-PERSIST
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import superNodeSlice, {
  SUPERNODE_SLICE_NAME,
} from './superNode/superNodeSlice';
import socketIOSlice, { SOCKETIO_SLICE_NAME } from './socketIO/socketIOSlice';
import modalSlice from './modal/modalSlice';
import newsLetterSlice, {
  NEWS_LETTER_SLICE_NAME,
} from './newsLetter/newsLetterSlice';
import scrollerSlice from './Scroller/scrollerSlice';
import sngpPoolSlice, { SNGP_SLICE_NAME } from './sngpPool/sngpPoolSlice';
import countryPoolSlice from './countryPool/poolSlice';
import specialSwapSlice, {
  SPECIAL_SWAP_SLICE_NAME,
} from './wallets/specialSwapSlice';
import cloudkSlice , { CLOUDK_SLICE_NAME } from './cloudk/cloudkSlice';
const appReducer = combineReducers({
  user: userSlice,
  drawer: drawerSlice,
  general: generalSlice,
  wallet: walletsSlice,
  notifications: notificationsSlice,
  [NEWS_LETTER_SLICE_NAME]: newsLetterSlice,
  setting: settingSlice,
  menuItems: menuItemsSlice,
  platform: platformSlice,
  modal: modalSlice,
  scroller: scrollerSlice,
  [SPECIAL_SWAP_SLICE_NAME]: specialSwapSlice,
  [WITHDRAW_SLICE_NAME]: withdrawSlice,
  [CLOULDK2_SLICE_NAME]: clouldk2Slice,
  [DEPOSIT_SLICE_NAME]: depositSlice,
  [SWAP_SLICE_NAME]: swapSlice,
  [WALLETDATA_SLICE_NAME]: walletDataSlice,
  [REWARD_SLICE_NAME]: rewardsSlice,
  [SUPERNODE_SLICE_NAME]: superNodeSlice,
  [SOCKETIO_SLICE_NAME]: socketIOSlice,
  [SNGP_SLICE_NAME]: sngpPoolSlice,
  [CLOUDK_SLICE_NAME]: cloudkSlice,

  countryPool: countryPoolSlice,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'user/setLogout') state = {} as RootState;
  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['user'],
  blacklist: ['drawer'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type ReduxAction = ReturnType<AppDispatch>;
export const persistor = persistStore(store);
