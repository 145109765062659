import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { API_INSTANCE_BID } from './ApiEndpoints';
import { clearStorage, getLoginToken } from '../storage/protectedStorage';
import { ACCESS_TOKEN, LOGIN_TOKEN } from '../constants';
import { PAGES } from '../routers';
import currentStorage from '../currentStorage';
import { store } from '../../store';
import { reset } from '../../store/user/userSlice';
import { showModal } from '../../store/modal/modalSlice';
import { toast } from 'react-toastify';

export const logout = () => {
  store.dispatch(reset());
  const storage = currentStorage();
  storage.removeItem(LOGIN_TOKEN);
  storage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem('valuesOfRememberMe');
  clearStorage();
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = PAGES.LOGIN;
};

export default function ApiRequestBID() {
  const loginToken = getLoginToken();
  const request = axios.create({
    baseURL: `${API_INSTANCE_BID}`,
    headers: {
      Authorization: `Token ${loginToken || null}`,
    },
    responseType: 'json',
    socketPath: null,
  });
  request.interceptors.request.use((config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const state = store.getState();
    if(state?.user?.user?.isImpersonateMode && config.method?.toUpperCase() !== 'GET'){
      toast.error('Impersonate mode is active. Non-GET requests are blocked.');
      throw new axios.Cancel();
    }
    return config;
  });

  request.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => response,
    (error: AxiosError) => {
      const statusCode = error.response?.status;
      const statusMessage = error?.code;
      
      if (error.code === 'ERR_NETWORK') {
        return Promise.reject(error);
      }

      if (statusMessage === 'ERR_BAD_REQUEST') {
        return Promise.reject(error);
      }
      if (statusCode === 401) {
        if (window.location.pathname !== '/auth/reset-password') {
          store.dispatch(showModal());
        }
        return Promise.reject(error);
      }

      if (error.status === 403) {
        store.dispatch(showModal());
        return Promise.reject(error);
      }

      return Promise.reject(error.response);
    }
  );

  return request;
}
