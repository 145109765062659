import React from 'react';

type Props = {
  Icon: React.ReactNode;
  label: string;
  className?: string;
  labelClass?: string;
};

const IconLabel = ({ Icon, label, className, labelClass }: Props) => {
  return (
    <div className={`w-full flex gap-2 ${className}`}>
      {Icon}
      <span className={`text-sm font-normal ${labelClass}`}>{label}</span>
    </div>
  );
};

export default IconLabel;
