import { Swiper } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import './cardSlider.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

type TSimpleSlider = {
  className?: string;
  slidesPerView?: any;
  spaceBetween?: number;
  data?: {}[];
  children: React.ReactNode;
  modules?: any;
  navigation?: any;
  pagination?: any;
  loop?: any;
  centeredSlides?: boolean;
  slideToClickedSlide?: boolean;
  onSlideChange?: (x: any) => any;
  onSwiper?: (x: any) => any;
  direction?: 'horizontal' | 'vertical' | undefined;
  breakpoints?: any;
  initialSlide?: any;
  onResize?: any;
  autoplay?: any;
};

export default function SimpleSlider({
  className,
  slidesPerView,
  spaceBetween,
  children,
  navigation,
  modules,
  loop,
  centeredSlides = false,
  slideToClickedSlide = false,
  onSlideChange,
  onSwiper,
  pagination,
  direction = 'horizontal',
  breakpoints,
  initialSlide,
  onResize,
  autoplay,
}: TSimpleSlider) {
  return (
    <Swiper
      slidesPerView={slidesPerView || 3}
      modules={modules ? modules : [Navigation, Pagination]}
      navigation={navigation}
      pagination={pagination}
      spaceBetween={spaceBetween}
      className={'mySwiper ' + className}
      loop={loop ? loop : false}
      centeredSlides={centeredSlides}
      slideToClickedSlide={slideToClickedSlide}
      onSlideChange={onSlideChange}
      onSwiper={onSwiper}
      direction={direction}
      breakpoints={breakpoints} // Ensure breakpoints are correctly passed
      onBreakpoint={breakpoints}
      initialSlide={initialSlide}
      onResize={onResize}
      autoplay={autoplay}
    >
      {children}
    </Swiper>
  );
}
