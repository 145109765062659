import React, { useEffect, useRef, useState } from 'react';
import CardLayout from '../../../../../components/Cards/CardLayout';
import { hardwareImg } from '../../../../../images/cloudk';
import { ReactComponent as LinkIcon } from '../../../../../SVGIcons/cloud/link.svg';
import { SwiperSlide } from 'swiper/react';
import SimpleSlider from '../../../../../components/Slider/CardSlider';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { Navigation } from 'swiper/modules';
import GlobalModal from '../../../../../components/Global/GlobalModal';
import ConnectNewMinter from './ConnectNewMinter/ConnectNewMinter';
import HardwareDetailModal from './HardwareDetailModal/HardwareDetailModal';
import useModalHook from '../../../../../hooks/useModalHook';
import { selectCloudSlice } from '../../../../../store/cloudk/cloudkSlice';
import { useSelector } from 'react-redux';
import { useCloudkActions } from '../../../../../store/cloudk/cloudkAction';
import { useNavigate } from 'react-router-dom';
const dummyData = [
  {
    upgradeAble: true,
    icon: hardwareImg,
    minterData:{
      name: 'LayerK XSTK-220',
      documents: [{ link: hardwareImg }],
      status: 'active',
    },
    lock: false,
    disable: false,
  },
  {
    upgradeAble: false,
    icon: hardwareImg,
    minterData: {
      name: 'LayerK XSTK-220',
      documents: [{ link: hardwareImg }],
      status: 'active',
    },
    lock: true,
    disable: false,
  },
  {
    upgradeAble: true,
    icon: hardwareImg,
   minterData:{
     name: 'LayerK XSTK-220',
     documents: [{ link: hardwareImg }],
      status: 'active',
    },
    lock: false,
    disable: true,
  },
  {
    upgradeAble: true,
    icon: hardwareImg,
   minterData:{
     name: 'LayerK XSTK-220',
     documents: [{ link: hardwareImg }],
      status: 'active',
    },
    lock: false,
    disable: false,
  },
  {
    upgradeAble: false,
    icon: hardwareImg,
   minterData:{
     name: 'LayerK XSTK-220',
     documents: [{ link: hardwareImg }],
      status: 'active',
    },
    lock: true,
    disable: false,
  },
  {
    upgradeAble: true,
    icon: hardwareImg,
   minterData:{
     name: 'LayerK XSTK-220',
     documents: [{ link: hardwareImg }],
      status: 'active',
    },
    lock: false,
    disable: true,
  },
  {
    upgradeAble: true,
    icon: hardwareImg,
   minterData:{
     name: 'LayerK XSTK-220',
     documents: [{ link: hardwareImg }],
      status: 'active',
    },
    lock: false,
    disable: true,
  },
  {
    upgradeAble: true,
    icon: hardwareImg,
   minterData:{
     name: 'LayerK XSTK-220',
     documents: [{ link: hardwareImg }],
      status: 'active',
    },
    lock: false,
    disable: true,
  },
];
const AllHardwares = () => {
  const sliderRef = useRef<any>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const { open, handleClose, handleOpen } = useModalHook();
  const [minterId, setMinterId] = useState('');
  const {
    open: openDetailModal,
    handleClose: handleCloseDetailModal,
    handleOpen: handleOpenDetailModal,
  } = useModalHook();

  const { allHardwareLoading, allHArdwaresData } =
    useSelector(selectCloudSlice);
    
  const { getAllHardwares } = useCloudkActions();
  const data = allHArdwaresData?.list?.length ? allHArdwaresData?.list  : dummyData;
  console.log({ data })

  const navigate = useNavigate();

  const handlePrev = () => {
    if (sliderRef.current && currentIndex > 0) {
      sliderRef.current.slidePrev();
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (sliderRef.current && !isAtEnd) {
      sliderRef.current.slideNext();
      setCurrentIndex(currentIndex + 1);
    }
  };

  useEffect(() => {
    getAllHardwares();
  }, []);

  return (
    // data?.list?.length > 0 && (
      <CardLayout className=" col-span-12  p-5 flex flex-col justify-between gap-2 !shadow-boxShadow">
        <section className="flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <h1 className="text-secondaryBlack font-medium sm:text-lg text-sm">
              Hardwares
            </h1>
            {/* <div
            className=" top-5 justify-end right-5 bottom-[-6px] md:bottom-5 md:right-[10px] gap-1 flex cursor-pointer"
            onClick={() => {
              //   navigate('/wallet/history');
            }}
          >
            <span className="text-sm font-normal">View All</span>
            <div className="h-5 w-5">
              <ArrowRight />
            </div>
          </div> */}
          </div>
        </section>
        <div className="relative">
          <div className="flex gap-2 overflow-x-hidden ">
            <SimpleSlider
              onSwiper={(swiper) => {
                sliderRef.current = swiper;
              }}
              onSlideChange={(swiper) => {
                setCurrentIndex(swiper.activeIndex);
                setIsAtEnd(swiper.isEnd); // Update end status here
              }}
              className="w-full"
              spaceBetween={8}
              slidesPerView={'auto'}
              modules={[Navigation]}
              navigation={false} // Disable default Swiper navigation
              breakpoints={{
                768: {
                  slidesPerView: 'auto',
                },
              }}
            >
              {data?.map((item: any, index: number) => {
                return (
                  <SwiperSlide key={index} className="!w-[220px] h-full pt-8 cursor-grab">
                    <div
                      style={{
                        background: item.upgradeAble ? "linear-gradient(93.11deg, #FAE140 1.51%, #F2C462 27.11%, #EB9A0E 52.32%, #F2C462 73.67%, #FFE668 101.91%)" : '',
                        border: !item.upgradeAble ? '1px solid #D9E4F1' : '',
                      }}
                      className={` p-[2px] relative rounded-lg`}
                    >
                      {item.upgradeAble && (
                        <div
                          style={{
                            background: "linear-gradient(93.11deg, #FAE140 1.51%, #F2C462 27.11%, #EB9A0E 52.32%, #F2C462 73.67%, #FFE668 101.91%)",
                          }}
                          className="cursor-pointer shadow-lg w-32 p-2 absolute  h-6 -top-3 left-1/2 -translate-x-1/2 z-20 rounded-xl flex justify-center items-center ">                          <span className="font-medium text-white  text-xs">
                            Upgrade Software
                          </span>
                        </div>
                      )}
                      <div
                        key={index}
                        className={`bg-white p-3 w-[216px] h-full rounded-lg relative flex flex-col gap-6`}
                      >
                        <div className="w-full flex justify-center mt-3">
                          <img
                            src={item.minterData?.documents[0]?.link}
                            alt="machine-image"
                            className="h-[103px] w-[176px]"
                          />
                        </div>

                        <div className="flex flex-col gap-2 w-full items-center">
                          <div className="flex flex-col gap-2 justify-center items-center">
                            <div className="flex gap-1 items-center">
                              <span className="font-medium text-[#121212]">
                                {item?.identifier}
                              </span>
                              <div className="relative w-2 h-2 ml-1">
                                <span className="flex gap-2 h-2 w-2">
                                  <span
                                    className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${
                                      item?.status == 'active'
                                        ? 'bg-primaryGreen'
                                        : 'bg-primaryRed'
                                    }`}
                                  ></span>
                                  <span
                                    className={`relative inline-flex rounded-full h-2 w-2 ${
                                      item?.status == 'active'
                                        ? 'bg-primaryGreen'
                                        : 'bg-primaryRed'
                                    }`}
                                  ></span>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-col gap-2 justify-center items-center my-1">
                            <div className="flex gap-1 items-center">
                              <span className="font-medium text-[#939393]">
                                Linked NFTS:
                              </span>
                              <div className="relative w-2 h-2 ml-1">
                                <span className="font-medium text-[#121212]">
                                  {`${item?.lockNft ?? 0}/${item?.minterData?.maxNFTCount ?? 0}`}
                                </span>
                              </div>
                            </div>
                          </div>

                          {item.type != 'LINKED' ? (
                            <span className="text-xs text-[#484848] underline cursor-pointer" onClick={() => {
                              setMinterId(item._id)
                              handleOpenDetailModal()
                            }}>
                              View Minter Details
                            </span>
                          ) : (
                            <button
                              disabled={item.disable}
                              type="button"
                              className={`w-44 h-6 rounded-[16px] flex justify-center items-center text-xs gap-1 text-white ${!item.lock && item.disable ? 'bg-[#C0D5DF] cursor-not-allowed' : 'hover:bg-primary'}  bg-[#00A5EF] `}
                            >
                              <div className="h-3 w-3">
                                <LinkIcon />
                              </div>{' '}
                              Connect
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </SimpleSlider>
          </div>

          {/* Fade effect */}
          <div className="absolute top-0 right-0 h-full w-[10%] pointer-events-none bg-gradient-to-l from-white md:block hidden"></div>
        </div>
        <div className="md:hidden flex gap-4 mt-2">
          <button
            onClick={handlePrev}
            disabled={currentIndex === 0}
            className={` rounded-full ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'} transition`}
          >
            <IoIosArrowBack size={20} />
          </button>
          <button
            onClick={handleNext}
            disabled={isAtEnd}
            className={` rounded-full ${isAtEnd ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'} transition`}
          >
            <IoIosArrowForward size={20} />
          </button>
        </div>
        <GlobalModal
          isOpen={open}
          onClose={handleClose}
          size="3xl"
          childrenClass="!px-0 !py-0 small-scroll"
          title={'Connect New Minter'}
          noHeader={false}
          headerClassName="!p-6  font-medium text-lg text-[#0E173F]"
        >
          <ConnectNewMinter />
        </GlobalModal>

        <GlobalModal
        isOpen={openDetailModal}
          onClose={handleCloseDetailModal}
          size="3xl"
          childrenClass="!px-0 !py-0 small-scroll"
          // title={'Connect New Minter'}
          
          noHeader={true}
          headerClassName="!p-6  font-medium text-lg text-[#0E173F]"
        >
          <HardwareDetailModal id={minterId} />
        </GlobalModal>
      </CardLayout>
    // )
  );
};

export default AllHardwares;
