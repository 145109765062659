import { MachineImage } from '../../images/cloud';
import { ALLPAGES, TCLOUNDK2_MACHINE } from '../../types/cloundk.type';

export const WalletButtons = [
  {
    value: 'LYK - D',
  },
  {
    value: 'LYK - W',
  },
  {
    value: 'LYK - R',
  },
  {
    value: 'sLYK',
  },
];
export const CoinButtons = [
  {
    value: 'LYK - D',
  },
  {
    value: 'LYK - W',
  },
  {
    value: 'LYK - R',
  },
];

export const direct_withdraw_percentage = [25, 50, 75, 100];

export const machine_list: TCLOUNDK2_MACHINE[] = [
  {
    id: '1',
    name: 'XKvalidator',
    bestSeller: true,
    image: MachineImage,
    rewards: 500,
    value: 25000,
  },
  {
    id: '2',
    name: 'XK1000',
    bestSeller: false,
    image: MachineImage,
    rewards: 500,
    value: 25000,
  },
  {
    id: '3',
    name: 'XK500',
    bestSeller: true,
    image: MachineImage,
    rewards: 500,
    value: 25000,
  },
  {
    id: '4',
    name: 'XK10000',
    bestSeller: true,
    image: MachineImage,
    rewards: 500,
    value: 25000,
  },
  {
    id: '5',
    name: 'XK500',
    bestSeller: true,
    image: MachineImage,
    rewards: 500,
    value: 25000,
  },
  {
    id: '6',
    name: 'XK5000',
    bestSeller: false,
    image: MachineImage,
    rewards: 500,
    value: 25000,
  },
  {
    id: '7',
    name: 'XK10000',
    bestSeller: false,
    image: MachineImage,
    rewards: 500,
    value: 25000,
  },
];

export const AMOUNT_PERCENTAGE_DATA = [25, 50, 75, 100];
export const AllPages: ALLPAGES[] = [
  { name: 'Home', value: '/' },
  { name: 'Wallet', value: '/wallet' },
  { name: 'Withdraw', value: '/wallet/withdraw' },
  { name: 'Deposit', value: '/wallet/deposit' },
  { name: 'Swap', value: '/wallet/swap' },
  { name: 'History', value: '/wallet/history' },
  { name: 'Defi 360', value: '/defi-360' },
  { name: 'CloudK 1.0', value: '/defi-360/cloudk1' },
  { name: 'NodeK', value: '/defi-360/nodek' },
  {
    name: 'WalleK',
    value: 'https://dev-wallek-client-frontend.wallek.io/dashboard',
    external: true,
  },
  // { name: 'WalleK', value: '/wallek' },
  // { name: 'My Community', value: '/community' },
  // { name: 'My Friends', value: '/community/friends' },
  { name: 'SuperNode', value: '/myfriends' },
  {
    name: 'Quantwise',
    value: 'https://staging-quantwise-front-pt.quantwise.ai/',
    external: true,
  },
  // { name: 'Quantwise', value: '/quantwise' },
  { name: 'External Apps', value: '/external-apps' },
  { name: 'My Vouchers', value: '/vouchers' },
];

export const notificationListData = [
  {
    _id: '66911755569e082ec172b935',
    userId: '66892901c18b02d6651fcd09',
    message: ` Attention! has just connected to your account. If you havent done this,please verify the security of your account`,
    read: false,
    code: 'pending',
    type: 'coming-soon',
    data: {
      title: 'This is the title',
      description: 'This is a description',
    },
    __v: 0,
    createdAt: '2024-07-12T11:45:25.674Z',
    updatedAt: '2024-07-12T11:45:25.674Z',
  },
  {
    _id: '66911755569e082ec172b935',
    userId: '66892901c18b02d6651fcd09',
    message: ` Attention! has just connected to your account. If you havent done this,please verify the security of your account`,
    read: false,
    type: 'coming-soon',
    code: 'pending',
    data: {
      title: 'This is the title',
      description: 'This is a description',
    },
    __v: 0,
    createdAt: '2024-07-12T11:45:25.674Z',
    updatedAt: '2024-07-12T11:45:25.674Z',
  },
  {
    _id: '66911755569e082ec172b936',
    userId: '66892901c18b02d6651fcd09',
    message: `If you havent done this,please verify the security of your account`,
    read: true,
    code: 'success',
    type: 'coming-soon',
    data: {
      title: 'This is the title',
      description: 'This is a description',
    },
    __v: 0,
    createdAt: '2024-07-12T11:45:25.674Z',
    updatedAt: '2024-07-12T11:45:25.674Z',
  },
  {
    _id: '66911755569e082ec172b937',
    userId: '66892901c18b02d6651fcd09',
    message: `please verify the security of your account`,
    read: true,
    code: 'warning',
    type: 'coming-soon',
    data: {
      title: 'This is the title',
      description: 'This is a description',
    },
    __v: 0,
    createdAt: '2024-07-12T11:45:25.674Z',
    updatedAt: '2024-07-12T11:45:25.674Z',
  },
  {
    _id: '66911755569e082ec172b938',
    userId: '66892901c18b02d6651fcd09',
    message: ` This is a description, please verify the security of your account`,
    read: true,
    code: 'error',
    type: 'transaction',
    data: {
      title: 'This is the title',
      description: 'This is a description',
    },
    __v: 0,
    createdAt: '2024-07-12T11:45:25.674Z',
    updatedAt: '2024-07-12T11:45:25.674Z',
  },
  {
    _id: '66911755569e082ec172b938',
    userId: '66892901c18b02d6651fcd09',
    message: ` hi attentions please verify the security of your account`,
    read: true,
    code: 'warning',
    type: 'transaction',
    data: {
      title: 'This is the title',
      description: 'This is a description',
    },
    __v: 0,
    createdAt: '2024-07-12T11:45:25.674Z',
    updatedAt: '2024-07-12T11:45:25.674Z',
  },
  {
    _id: '66911755569e082ec172b935',
    userId: '66892901c18b02d6651fcd09',
    message: ` Attention! has just connected to your account. If you havent done this,please verify the security of your account`,
    read: false,
    type: 'coming-soon',
    code: 'pending',
    data: {
      title: 'This is the title',
      description: 'This is a description',
    },
    __v: 0,
    createdAt: '2024-07-12T11:45:25.674Z',
    updatedAt: '2024-07-12T11:45:25.674Z',
  },
  {
    _id: '66911755569e082ec172b936',
    userId: '66892901c18b02d6651fcd09',
    message: `If you havent done this,please verify the security of your account`,
    read: true,
    code: 'success',
    type: 'coming-soon',
    data: {
      title: 'This is the title',
      description: 'This is a description',
    },
    __v: 0,
    createdAt: '2024-07-12T11:45:25.674Z',
    updatedAt: '2024-07-12T11:45:25.674Z',
  },
  {
    _id: '66911755569e082ec172b937',
    userId: '66892901c18b02d6651fcd09',
    message: `please verify the security of your account`,
    read: true,
    code: 'warning',
    type: 'coming-soon',
    data: {
      title: 'This is the title',
      description: 'This is a description',
    },
    __v: 0,
    createdAt: '2024-07-12T11:45:25.674Z',
    updatedAt: '2024-07-12T11:45:25.674Z',
  },
  {
    _id: '66911755569e082ec172b937',
    userId: '66892901c18b02d6651fcd09',
    message: `please verify the security of your account`,
    read: true,
    code: 'warning',
    type: 'coming-soon',
    data: {
      title: 'This is the title',
      description: 'This is a description',
    },
    __v: 0,
    createdAt: '2024-07-12T11:45:25.674Z',
    updatedAt: '2024-07-12T11:45:25.674Z',
  },
];
