import moment from 'moment';
import {
  AUSDIcon,
  PUSDIcon,
  USDTIcon,
  XPLIcon,
} from '../components/Global/CurrencyIcons';

export const COMPANY_NAME: string = 'PULSEWORLD';
export const WEBSITE = 'https://pulseworld.com';
export const COMPANY_EMAIL: string = 'support@pulseworld.com';

export enum TUserMembershipStatus {
  ACTIVE = 'ACTIVE',
  NO_MEMBERSHIP = 'NO_MEMBERSHIP',
  EXPIRED = 'EXPIRED',
}

// export enum TransactionEvents {
//   WITHDRAW_TRANSACTION = 'WITHDRAW_TRANSACTION',
//   DEPOSIT_TRANSACTION = 'DEPOSIT_TRANSACTION',
// }

export enum TransactionEvents {
  WITHDRAW_TRANSACTION_SUCCESS = 'WITHDRAW_TRANSACTION_SUCCESS',
  DEPOSIT_TRANSACTION_SUCCESS = 'DEPOSIT_TRANSACTION_SUCCESS',
  DEPOSIT_AND_STAKE_TRANSACTION_SUCCESS = 'DEPOSIT_AND_STAKE_TRANSACTION_SUCCESS',
  DEPOSIT_AND_STAKE_TRANSACTION_FAILED = 'DEPOSIT_AND_STAKE_TRANSACTION_FAILED',
  WITHDRAW_TRANSACTION_FAILURE = 'WITHDRAW_TRANSACTION_FAILURE',
  DEPOSIT_TRANSACTION_FAILURE = 'DEPOSIT_TRANSACTION_FAILURE',
  NEW_NOTIFICATION_EVENT = 'NEW_NOTIFICATION',
}

export enum TOKEN {
  XPL = 'XPL',
  AUSD = 'AUSD',
  PUSD = 'PUSD',
  USDT = 'USDT',
}

export const TOKEN_COLORS = {
  XPL: '#F26BD4',
  PUSD: '#6289EE',
  AUSD: '#EEB662',
  USDT: '#53AE94',
};

export const SHADOW_CLASSES = {
  XPL: 'XPL-Shadow',
  PUSD: 'PUSD-Shadow',
  AUSD: 'AUSD-Shadow',
  USDT: 'USDT-Shadow',
};

export const TOKEN_ROUND_ICONS: any = {
  XPL: XPLIcon,
  PUSD: PUSDIcon,
  AUSD: AUSDIcon,
  USDT: USDTIcon,
};

export const POSITION_INDEX = {
  CUSTOMER: 0,
  SUBAGENT: 1,
  AGENT: 2,
  AREAMANAGER: 3,
};

export const REFFER_URL: string = WEBSITE + '/auth/signup?ref=';

export const momentFormat = (date: any) => {
  return moment(new Date(date), 'YYYYMMDD').fromNow();
};

export const formatDate = (dateString: any) => {
  return moment(dateString).format('YYYY-MM-DD');
};

//for notification
export const formatRelativeTime = (timestamp: moment.MomentInput) => {
  const now = moment();
  const createdTime = moment(timestamp);
  const duration = moment.duration(now.diff(createdTime));

  const years = Math.floor(duration.asYears());
  const months = Math.floor(duration.asMonths());
  const weeks = Math.floor(duration.asWeeks());
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (years > 0) {
    return `${years}y`;
  } else if (months > 0) {
    return `${months}mo`;
  } else if (weeks > 0) {
    return `${weeks}w`;
  } else if (days > 0) {
    return `${days}d`;
  } else if (hours > 0) {
    return `${hours}h`;
  } else if (minutes > 0) {
    return `${minutes}m`;
  } else {
    return `${seconds}s`;
  }
};

export enum LINK_TYPE {
  WIKI = 'WIKI',
  LEGAL = 'LEGAL',
}
