//REDUX-TOOLKIT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CloudkState {
  activeRewardTab: any;
  activeRewardTime: any;
  isGenerationSwitch: boolean;
  totalLicenses: number;
  activeLicenses: number;
  inActiveLicenses: number;
  totalLinked: number;
  activeLinked: number;
  availableLinkedCapacity: number;
  totalStacked: number;
  availableCloudKBalance: number;
  claimableRewardLoading: boolean;
  rewardAnalyticsGraphLoading: boolean;
  allLicienceLoading: boolean;
  allHardwareLoading: boolean;
  transactionHistoryLoading: boolean;
  machineRewardHistoryLoading: boolean;
  detailDataLoading: boolean;
  upgradeAbleMachinesLoading: boolean;
  createMintingTokenLoading: boolean;
  connectNewMinterLoading: boolean;
  dailyActivityLoading: boolean;
  deLinkLoading: boolean;
  hardwareClaimLoading: boolean;
  claimableRewardAutoLinkLoading: boolean;
  allLiciencesData: any[];
  allHArdwaresData: any;
  rewardAnalyticsGraphData: any[];
  transactionHistoryData: any;
  machineRewardHistoryData: any[];
  upgradeAblesMachinesData: any[];
  detailedData: any[];
  dailyActivityData: any[];
  minterRewardGeneratedData : any;
  minterLinkedLicenses: any;
  allLiciencesIds: any;
  allLiciencesStatus: any;
}

export const CLOUDK_SLICE_NAME = 'cloudk';

const initialState: CloudkState = {
  activeRewardTab: { key: 'Licenses', label: 'Licenses' },
  activeRewardTime: { key: '3month', label: '3M' },
  isGenerationSwitch: false,
  totalLicenses: 0,
  activeLicenses: 0,
  inActiveLicenses: 0,
  totalLinked: 0,
  activeLinked: 0,
  availableLinkedCapacity: 50,
  totalStacked: 0,
  availableCloudKBalance: 20,
  claimableRewardLoading: false,
  rewardAnalyticsGraphLoading: false,
  allLicienceLoading: false,
  allHardwareLoading: false,
  transactionHistoryLoading: false,
  machineRewardHistoryLoading: false,
  detailDataLoading: false,
  upgradeAbleMachinesLoading: false,
  createMintingTokenLoading: false,
  connectNewMinterLoading: false,
  dailyActivityLoading: false,
  deLinkLoading: false,
  hardwareClaimLoading: false,
  claimableRewardAutoLinkLoading: false,
  allLiciencesData: [],
  allHArdwaresData: [],
  rewardAnalyticsGraphData: [],
  transactionHistoryData: [],
  machineRewardHistoryData: [],
  upgradeAblesMachinesData: [],
  detailedData: [],
  minterLinkedLicenses:[],
  dailyActivityData: [],
  minterRewardGeneratedData : [],
  allLiciencesIds: {},
  allLiciencesStatus: {},
};

const cloudkSlice = createSlice({
  name: CLOUDK_SLICE_NAME,
  initialState,
  reducers: {
    setCloudkSlice: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const cloudkSliceAction = cloudkSlice.actions;
export const { setCloudkSlice } = cloudkSlice.actions;
export const selectCloudSlice = (state: { [CLOUDK_SLICE_NAME]: CloudkState }) =>
  state[CLOUDK_SLICE_NAME];

export default cloudkSlice.reducer;
