import CardLayout from '../../../../../components/Cards/CardLayout'
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { selectCloudSlice, setCloudkSlice } from '../../../../../store/cloudk/cloudkSlice';
import LineChart from '../../../../../components/Charts/LineChart';
import { ReactComponent as Spinner } from '../../../../../SVGIcons/spinner.svg';
import { useEffect, useState } from 'react';
import { useCloudkActions } from '../../../../../store/cloudk/cloudkAction';
import CloudKSelect from '../../Common/CloudKSelect/CloudKSelect';
import { AnyObjectSchema } from 'yup';


let tabs = [
    { key: 'Licenses', label: 'Licenses' },
    { key: 'Devices', label: 'Devices' },
    // { key: 'All', label: 'All' },
];

let timeTabs = [
    { key: '3month', label: '3M' },
    { key: '6month', label: '6M' },
    { key: '1year', label: '1Y' },
    { key: 'all', label: 'All' },
];

const data = [
    {
        id: "LYK-R",
        color: "hsl(202, 100%, 80%)",
        data: [

            { x: "Jan", y: 11.7 },
            { x: "Feb", y: 11.9 },
            { x: "Mar", y: 12.1 },
        ]
    }, {
        id: "LYK-W",
        color: "hsl(234, 100%, 80%)",
        data: [

            { x: "Jan", y: 12.7 },
            { x: "Feb", y: 12.9 },
            { x: "Mar", y: 13.1 },

        ]
    },
];

const RewardAnalytics = () => {
    const { activeRewardTab, activeRewardTime, rewardAnalyticsGraphLoading, rewardAnalyticsGraphData } = useSelector(selectCloudSlice)
    const dispatch = useDispatch()
    const { rewardAnalyticsGraphDataFunc } = useCloudkActions()
    const [elementOptions, setElementOptions] = useState<any>([])
    const [timeOptions, setTimeOptions] = useState<any>([])



    useEffect(() => {
        if (tabs?.length > 0) {
            setElementOptions(tabs?.map((tab: any) => ({
                value: tab.key,
                label: tab.label
            })))
        }
    }, [tabs]);

    useEffect(() => {
        if (timeTabs.length > 0) {
            setTimeOptions(timeTabs?.map((tab: any) => ({
                value: tab.key,
                label: tab.label
            })))
        }
    }, [timeTabs]);

    const handleButtonClick = (buttonText: any) => {
        dispatch(setCloudkSlice({
            activeRewardTab: buttonText
        }))
    };
    const handleTimeTabClick = (buttonText: any) => {
        dispatch(setCloudkSlice({
            activeRewardTime: buttonText
        }))
    };

    useEffect(()=>{
        // rewardAnalyticsGraphDataFunc(activeRewardTab.key, activeRewardTime.key ,'123' )
    }, [activeRewardTab, activeRewardTime])

    const handleElementChange = (selectedOption: { label: string; value: string }) => {
        dispatch(setCloudkSlice({
            activeRewardTab: selectedOption
        }))
    };

    const handleLicensesChange = (
        selectedOption: { label: string; value: string }) => {

        dispatch(setCloudkSlice({
            activeRewardTime: selectedOption
        }))
    };
    return (
        <CardLayout className="md:col-span-7 col-span-12  p-5 flex flex-col justify-between gap-2 !shadow-boxShadow">
            <section className="flex flex-col gap-4">
                <div className='flex justify-between items-center  gap-2 flex-wrap'>
                    <h1 className="text-secondaryBlack font-medium sm:text-lg text-sm">
                        Reward Analytics
                    </h1>
                    <div className='flex md:hidden'>
                        <div className='flex gap-2 justify-end flex-wrap'>
                             <CloudKSelect
                                options={elementOptions}
                                placeholder=""
                                onChange={handleElementChange} // Handle the option change
                                selectedOption={activeRewardTab}
                                btnStyle={'!gap-x-0 !bg-[#EAF4FF]'}
                            />
                            <CloudKSelect selectedOption={activeRewardTime}
                                options={timeOptions} placeholder="" onChange={handleLicensesChange} 
                                btnStyle={'!gap-x-0 !bg-[#EAF4FF]'} />
                        </div>  

                    </div>
                    <div className='hidden  md:flex'>
                    <div className='flex gap-2 justify-end '>
                        {tabs.map((tab) => (
                            <button
                                key={tab.key}
                                onClick={() => handleButtonClick(tab)}
                                className={`!px-3 !py-1  max-w-44 font-normal rounded-lg transition-all
                              hover:text-primary bg-transparent border h-10 ${activeRewardTab.key === tab.key && ' !bg-[#EAF4FF]'}`}
                                style={{
                                    transformStyle: 'preserve-3d',
                                }}
                            >
                                {activeRewardTab.key === tab.key && (
                                    <motion.span
                                        layoutId="bubble"
                                        className="absolute inset-0 bg-inherit rounded-lg !border-primary"
                                        transition={{
                                            type: 'spring',
                                            bounce: 0.2,
                                            duration: 0.6,
                                        }}
                                    />
                                )}
                                <span
                                    className={`relative block ${activeRewardTab.key === tab.key ? 'text-[#344054]' : 'text-[#344054]'}`}
                                >
                                    {tab.label}
                                </span>
                            </button>
                        ))}
                    </div>
                    </div>
                </div>
                <div className='hidden  md:block' >
                <div className='flex gap-2'>
                    {
                        timeTabs.map((time) => {
                            return (
                                <div onClick={() => handleTimeTabClick(time)}
                                    className={`flex cursor-pointer justify-center items-center h-10 w-14 border ${activeRewardTime.key === time.key ? 'bg-[#EAF4FF]' : 'bg-[#FFFFFF]'}  rounded-lg text-sm font-medium`}
                                    key={time.key}>
                                    {time.label}
                                </div>
                            )
                        })
                    }
                </div>
                </div>
                <article className="w-full  h-[150px]">
                    {rewardAnalyticsGraphLoading ? (
                        <div className="flex justify-center items-center flex-1  h-full w-full">
                            <div className="justify-center items-center self-center">
                                <div className="flex flex-col gap-5">
                                    <Spinner className="animate-spin h-6" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="h-full w-full">
                            <LineChart
                            containerClassName={'!px-0'}
                                data={data}
                               isCloudKChart={true}
                                    xScale={{ type: 'point' }}
                                    yScale={{
                                        type: 'linear',
                                        min: 'auto',
                                        max: 'auto',
                                        stacked: true,
                                        reverse: false
                                    }}
                                    pointSize={7}
                                    pointColor={{ from: 'color', modifiers: [] }}
                                    pointBorderWidth={2}
                                    pointBorderColor={{ from: 'color', modifiers: [] }}

                                    pointLabel="data.yFormatted"
                                    pointLabelYOffset={-16}
                                    areaOpacity={0.1}
                                    
                                // defs={TotalRewardsChartDefs}
                                // fill={TotalRewardsChartFill}
                                    margin={{ top: 20, right: 15, bottom: 35, left: 15 }}
                                isInteractive={true}
                                enablePoints={false}
                                    enableGridX={false}
                                enableGridY={true}
                                enableArea={true}
                                    colors={['#7FC9FF','#7583FF']}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{                                       
                                        tickSize: 3,          // Shorten tick lines for a cleaner look
                                        tickPadding: 15,       // Bring labels closer to the chart
                                        tickRotation: 0,
                                        legend: '',
                                        legendOffset: 32,     // Adjust positioning of legend if needed
                                        legendPosition: 'end',
                                        truncateTickAt: 100,
                                    }}
                                    axisLeft={null}
                                theme={{
                                    text: {
                                        fontSize: '12px',
                                        fill: '#AAAAAA',
                                    },
                                    grid: {
                                        line: {
                                            stroke: '#E0E0E0', // Ensure this is a visible color
                                            strokeWidth: 1,
                                            // strokeDasharray: [6, 4],
                                        },
                                        top: {
                                            line: {
                                                stroke: 'transparent', // Make the top grid line invisible
                                                strokeWidth: 0,
                                            },
                                        },
                                    },
                                    axis: {
                                        ticks: {
                                            line: {
                                                stroke: '#FFFFFF40',
                                            },
                                            text: {
                                                fontSize: '14px',
                                                fill: '#616161',
                                            },
                                        },
                                    },
                  
                                }}
                                    
                                    legends={[
                                        {
                                            anchor: 'top-right',
                                            direction: 'row',
                                            justify: false,
                                            translateX: 0,
                                            translateY: -25,
                                            itemWidth: 55,
                                            itemHeight: 25,
                                            itemsSpacing: 20,
                                            symbolSize: 9,
                                            symbolShape: 'circle',
                                            itemDirection: 'left-to-right',
                                            itemTextColor: '#616161',
                                            textStyle: { fontSize: 16 },

                                            // effects: [
                                            //     {
                                            //         on: 'hover',
                                            //         style: {
                                            //             itemBackground: 'rgba(0, 0, 0, .03)',
                                            //             itemOpacity: 1
                                            //         }
                                            //     }
                                            // ]
                                        }
                                    ]}
                                
                                gridXValues={2} 
                                gridYValues={4}
                                tootlipColor={'#292929'}
                                // tootlipText={'LYK-W'}
                                showSub={true}
                                curve={'basis'}
                                        

                            />
                        </div>
                    )}
                </article>
            </section>
       
        </CardLayout>

    )
}

export default RewardAnalytics
