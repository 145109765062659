function useCopyTextHook() {
  const writeClipboardText = async (text: string) => {
    try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(text);
      } else {
        fallbackWriteClipboardText(text);
      }
    } catch (error) {
      fallbackWriteClipboardText(text);
    }
  };

  const fallbackWriteClipboardText = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    textArea.setSelectionRange(0, 99999); // For mobile devices

    try {
      document.execCommand('copy');
    } catch (error) {
      console.error('Fallback copy to clipboard failed: ', error);
    }

    document.body.removeChild(textArea);
  };

  return {
    writeClipboardText,
  };
}

export default useCopyTextHook;
