import { Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/modal';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

export interface GlobalModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  title?: string;
  children?: React.ReactNode;
  size?:
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | 'full'
  | undefined;
  noHeader?: boolean;
  hideCloseButton?: boolean;
  isDismissable?: boolean;
  childrenClass?: string;
  rounded?: string;
  scrollBehavior?: 'normal' | 'inside' | 'outside';
  fullWidthContent?: string;
  modalCenter?: string;
  headerClassName?:string;
}

const GlobalModal: React.FC<GlobalModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  size,
  noHeader,
  hideCloseButton,
  childrenClass,
  scrollBehavior,
  isDismissable,
  rounded = 'rounded-xl',
  fullWidthContent,
  modalCenter,
  headerClassName
}) => {
  const isLaptop = useMediaQuery({
    query: '(max-width: 1024px)',
  });

  return (
    <>
      <Modal
        placement="center"
        hideCloseButton={hideCloseButton}
        backdrop="blur"
        size={size}
        isOpen={isOpen}
        onClose={onClose}
        shouldBlockScroll={false}
        scrollBehavior={isLaptop ? 'inside' : 'outside'}
        isDismissable={isDismissable}
        motionProps={{
          variants: {
            enter: {
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.3,
                ease: 'easeOut',
              },
            },
            exit: {
              y: -20,
              opacity: 0,
              transition: {
                duration: 0.2,
                ease: 'easeIn',
              },
            },
          },
        }}
        classNames={{
          wrapper: `z-[999] small-scroll ${modalCenter ? modalCenter : ''}`,
          backdrop: 'bg-[#292f46]/50 backdrop-opacity-40',
        }}
        
      >
        <ModalContent
          className={`bg-white border ${rounded} ${fullWidthContent}  `}
        >
          <>
            {!noHeader ? (
              <ModalHeader className={`flex flex-col gap-1 font-medium ${headerClassName}`}>
                {title}
              </ModalHeader>
            ) : (
              ''
            )}

            <ModalBody className={childrenClass}>{children}</ModalBody>
          </>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GlobalModal;
