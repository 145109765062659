import React, { useEffect, useState } from 'react'
import { ReactComponent as LinkIcon } from '../../../../../../SVGIcons/cloud/link.svg';
import { licenseImg } from '../../../../../../images/cloudk';
import CloudKTable from '../../../Common/CloudKTable/CloudKTable';
import CloudKSelect from '../../../Common/CloudKSelect/CloudKSelect';

import { formatDateInto2Digits } from '../../../../../../utils/utils';
import { useCloudkActions } from '../../../../../../store/cloudk/cloudkAction';
import { useSelector } from 'react-redux';
import { selectCloudSlice } from '../../../../../../store/cloudk/cloudkSlice';
import { ReactComponent as Spinner } from '../../../../../../SVGIcons/spinner.svg';
import TotalLinked from './TotalLinked';
import SimpleSlider from '../../../../../../components/Slider/CardSlider';
import { SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';


const data = {
    lockNft: 2,
    type: "LINKED",
    userMinterId: 103550000201,
    userMinterData:{
        "_id": "6732ea67c0401e9d14918107",
        "minter": "6732e97cd7fad5f5fae7931d",
        "maxLicenseLimit": 5,
        "maxNftHold": 5,
    },

    MinterData:{
        "_id": "6732e97cd7fad5f5fae7931d",
        "code": "65c0d48e4f4732ae561da7c3",
        "name": "xK 500",
        "maxLicenseLimit": 5,
        "maxNFTCount": 5,
        "licenseinfo": {
            "_id": "6732e959957647f3fb3c8814",
            "name": "xK 500 license",
            "code": "sm-65c0d48e4f4732ae561da7c3",
            "status": "active",
            "nft": 2,
            "collateral": 440,
            "type": "custom",
            "images": [
                {
                    "_id": "6732e951957647f3fb3c8787",
                    "link": "https://api.lyotechlabs.com/public/product/images/df97620b-bfcb-4888-b433-90d898d9a1a3.jpg",
                    "type": "image",
                    "deletedAt": null,
                    "createdAt": "2024-11-12T05:36:17.382Z",
                    "updatedAt": "2024-11-12T05:36:17.382Z"
                },
                {
                    "_id": "6732e951957647f3fb3c8788",
                    "link": "https://api.lyotechlabs.com/public/product/images/79388925-05e1-4a81-8a02-de49aaa208c7.jpg",
                    "type": "image",
                    "deletedAt": null,
                    "createdAt": "2024-11-12T05:36:17.382Z",
                    "updatedAt": "2024-11-12T05:36:17.382Z"
                },
                {
                    "_id": "6732e951957647f3fb3c8789",
                    "link": "https://api.lyotechlabs.com/public/product/images/b02fda47-ef9c-4ccc-94ce-bbead486ea1e.jpg",
                    "type": "image",
                    "deletedAt": null,
                    "createdAt": "2024-11-12T05:36:17.382Z",
                    "updatedAt": "2024-11-12T05:36:17.382Z"
                },
                {
                    "_id": "6732e951957647f3fb3c878a",
                    "link": "https://api.lyotechlabs.com/public/product/images/4240ccc5-209c-47fc-a9c3-ddf49888c947.jpg",
                    "type": "image",
                    "deletedAt": null,
                    "createdAt": "2024-11-12T05:36:17.382Z",
                    "updatedAt": "2024-11-12T05:36:17.382Z"
                }
            ],
            "meta": {
                "message": "The license has been successfully updated in the database via integration."
            },
            "kmallLicenseId": "65c0d4595d57ea39596cc4a5",
            "licenceCreatedDate": "2024-02-05T12:28:09.642Z",
            "deletedAt": null,
            "createdAt": "2024-02-05T12:28:09.642Z",
            "updatedAt": "2024-10-23T09:05:02.984Z"
        }

    },
    "stakedPercentage": 40,
    name: 'xK Validator',
    serialNumber: 103550000201,
    
    totalMlyk: '42,75565495 mLYK',
    tokensDetail: [{
        tokenName: 'LYK-R',
        tokenAmount: '4562,124',
    }, {
        tokenName: 'LYK-W',
        tokenAmount: '4562,124',
    }],
    licenseDetails:[{
        licenseName: 'LayerK XSTK-220 ',
        licenseImage : licenseImg,
        licenseNumber: 'CLDX-01724923571144',
        noOfNfts : 2,
        availableBalance: '4562,124',
        availableBalanceToken:'ScLFi',
        isActive: false

    },
    //  {
    //     licenseName: 'LayerK XSTK-222',
    //         licenseImage: licenseImg,
    //     licenseNumber: 'CLDX-01724923571144',
    //         noOfNfts: 2,
    //     availableBalance: '4562,124',
    //     availableBalanceToken: 'ScLFi',
    //         isActive: true

    //     },
    //     {
    //         licenseName: 'LayerK XSTK-222',
    //         licenseImage: licenseImg,
    //         licenseNumber: 'CLDX-01724923571144',
    //         noOfNfts: 2,
    //         availableBalance: '4562,124',
    //         availableBalanceToken: 'ScLFi',
    //         isActive: true

    //     },
    //     {
    //         licenseName: 'LayerK XSTK-222',
    //         licenseImage: licenseImg,
    //         licenseNumber: 'CLDX-01724923571144',
    //         noOfNfts: 2,
    //         availableBalance: '4562,124',
    //         availableBalanceToken: 'ScLFi',
    //         isActive: true

    //     },
    //     {
    //         licenseName: 'LayerK XSTK-222',
    //         licenseImage: licenseImg,
    //         licenseNumber: 'CLDX-01724923571144',
    //         noOfNfts: 2,
    //         availableBalance: '4562,124',
    //         availableBalanceToken: 'ScLFi',
    //         isActive: true

    //     },
    //     {
    //         licenseName: 'LayerK XSTK-222',
    //         licenseImage: licenseImg,
    //         licenseNumber: 'CLDX-01724923571144',
    //         noOfNfts: 2,
    //         availableBalance: '4562,124',
    //         availableBalanceToken: 'ScLFi',
    //         isActive: true

    //     }, {
    //         licenseName: 'LayerK XSTK-222',
    //         licenseImage: licenseImg,
    //         licenseNumber: 'CLDX-01724923571144',
    //         noOfNfts: 2,
    //         availableBalance: '4562,124',
    //         availableBalanceToken: 'ScLFi',
    //         isActive: true

    //     },
    //  {
    //     licenseName: 'LayerK XSTK-222',
    //         licenseImage: licenseImg,
    //     licenseNumber: 'CLDX-01724923571144',
    //         noOfNfts: 2,
    //     availableBalance: '4562,124',
    //     availableBalanceToken: 'ScLFi',
    //         isActive: true

    //     },
    //     {
    //         licenseName: 'LayerK XSTK-222',
    //         licenseImage: licenseImg,
    //         licenseNumber: 'CLDX-01724923571144',
    //         noOfNfts: 2,
    //         availableBalance: '4562,124',
    //         availableBalanceToken: 'ScLFi',
    //         isActive: true

    //     },
    ]
}
const monthsData = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
const yearsData = Array.from({ length: 11 }, (_, i) => new Date().getFullYear() - i);

const HardwareDetailModal = ({id}:any) => {
    const { minterRewardGeneratedData ,minterLinkedLicenses, hardwareClaimLoading, machineRewardHistoryData, machineRewardHistoryLoading, dailyActivityData, dailyActivityLoading, deLinkLoading } = useSelector(selectCloudSlice)

    const { hardwareGeneratedRewardClaimfunc, getMachineRewardHistory, deLinkLicenseFunc, getUserMinterLinkedLicenses, getUserMinterDetailFunc } =useCloudkActions()
    const currentMonth = new Date().getMonth(); // 0 = January
    const currentYear = new Date().getFullYear();
    // State for selected month, year, and days in the month
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [daysInMonth, setDaysInMonth] = useState<any>(0);
    const [claimIndex, setClaimIndex] = useState<any>(0);
    const [deLinkIndex, setDeLinkIndex] = useState<any>(0);
    const navigate = useNavigate()
    useEffect(() => {
        const days = new Date(selectedYear, selectedMonth + 1, 0).getDate();
        setDaysInMonth(days);
    }, [selectedMonth, selectedYear]);
    
    const columns = [
        { label: 'Date', key: 'date', classNames: '!text-center text-[#939393] font-medium' },
        { label: 'Linked License', key: 'licenseId', classNames: ' !text-center text-[#939393] font-medium'},
        { label: 'Hardware Benefit', key: 'hardwareBenifit', classNames: '!text-center text-[#939393] font-medium'},
        { label: 'License Rewards', key: 'licenseReward', classNames: '!text-center text-[#939393] font-medium'},        
    ];
    const date = new Date(); 
    const formattedDate = formatDateInto2Digits(date);

    const rows = [
        {
            date: <p className="text-[#121212] text-xs text-center">
                    {formattedDate}
                </p>,
            licenseId: <p className="text-[#121212] text-xs text-center ">
                CLDX-01724923571144
            </p>,
            hardwareBenifit: <div className='flex flex-col gap-2 items-center w-full'>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-R</span>
                    <span className='text-xs font-medium text-[#7FC9FF]'>4562,124</span>
                </div>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-W</span>
                    <span className='text-xs font-medium text-[#7583FF]'>4562,124</span>
                </div>
            </div>,
            licenseReward: <div className='flex flex-col gap-2  items-center w-full'>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-R</span>
                    <span className='text-xs font-medium text-[#7FC9FF]'>4562,124</span>
                </div>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-W</span>
                    <span className='text-xs font-medium text-[#7583FF]'>4562,124</span>
                </div>
            </div>,
        },
        {

            date: (
                <p className="text-[#121212] text-xs text-center">
                    {formattedDate}
                </p>
            ),
            licenseId: <p className="text-[#121212] text-xs text-center">
                CLDX-01724923571144
            </p>,
            hardwareBenifit: <div className='flex flex-col gap-2 items-center w-full'>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-R</span>
                    <span className='text-xs font-medium text-[#7FC9FF]'>4562,124</span>
                </div>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-W</span>
                    <span className='text-xs font-medium text-[#7583FF]'>4562,124</span>
                </div>
            </div>,
            licenseReward: <div className='flex flex-col gap-2 items-center w-full'>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-R</span>
                    <span className='text-xs font-medium text-[#7FC9FF]'>4562,124</span>
                </div>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-W</span>
                    <span className='text-xs font-medium text-[#7583FF]'>4562,124</span>
                </div>
            </div>,
        },
        {

            date: (
                <p className="text-[#121212] text-xs text-center">
                    {formattedDate}
                </p>
            ),
            licenseId: <p className="text-[#121212] text-xs text-center">
                CLDX-01724923571144
            </p>,
            hardwareBenifit: <div className='flex flex-col gap-2 items-center w-full'>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-R</span>
                    <span className='text-xs font-medium text-[#7FC9FF]'>4562,124</span>
                </div>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-W</span>
                    <span className='text-xs font-medium text-[#7583FF]'>4562,124</span>
                </div>
            </div>,
            licenseReward: <div className='flex flex-col gap-2 items-center w-full'>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-R</span>
                    <span className='text-xs font-medium text-[#7FC9FF]'>4562,124</span>
                </div>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-W</span>
                    <span className='text-xs font-medium text-[#7583FF]'>4562,124</span>
                </div>
            </div>,
        },
    ];
    const [resetFilter, setResetFilter] = useState(false);
    const [filters, setFilters] = useState<any>({
        limit: 10,
        page: 1,
        type: null,
        time: null,

    });
    const [resetDisable, setResetDisable] = useState(true);

    useEffect(() => {
        const noData = !rows?.length;
        const hasFilters = filters.type || filters.time;

        // If there's no data and no filters, keep it disabled
        // If there's data but no filters, disable it
        // If there's data or filters are applied, enable it
        setResetDisable(noData || (!noData && !hasFilters));
    }, [rows, filters]);

    useEffect(() => {
        setResetFilter(false);
        // getCloudKTransactionHistory({
        //     page: filters.page,
        //     limit: filters.limit,
        //     licienceId: filters?.licenseId?.value,
        //     transactionType: filters?.licensesStatus?.value
        // });

    }, [filters]);
    const handleTypeChange = (selectedOption: { label: string; value: string }) => {
        setFilters((prev: any) => ({
            ...prev,
            type: selectedOption,
        }));
    };
    const handleTimeChange = (selectedOption: { label: string; value: string }) => {
        setFilters((prev: any) => ({
            ...prev,
            time: selectedOption,
        }));
    };
    const options = [
        { label: 'Option 1', value: '' },
        { label: 'Option 1', value: '1' },
        { label: 'Option 2', value: '2' },
        { label: 'Option 3', value: '3' },
    ];
    useEffect(()=>{
        // getMachineRewardHistory()
        getUserMinterDetailFunc({ id: id })
        getUserMinterLinkedLicenses({
            page : 1,
            limit : 10,
            id: id
        })
    },[])

    useEffect(() => {
        // getDailyMinterActivityFunc()
    }, [selectedMonth, selectedYear ])
    return (
        <div className='flex flex-col gap-2 p-6'>
            <div className='flex-col md:flex-row  flex gap-5 items-start md:items-center'>
                <span className='font-medium text-4xl text-[#0E173F]'>{data.MinterData.name}</span>
                <div className='flex gap-2'>
                    <button
                        //   onClick={() => {
                        //       !item.lock && handleOpen()
                        //   }}
                        //   disabled={item.disable}
                        type="button"
                        className={`px-4 h-8 rounded-[16px] flex justify-center items-center text-sm gap-1 border border-[#00A5EF] text-[#00A5EF] bg-white`}
                    >
                        Reboot
                    </button>
                    <button
                        //   onClick={() => {
                        //       !item.lock && handleOpen()
                        //   }}
                        //   disabled={item.disable}
                        type="button"
                        className={`p-4 h-8 rounded-[16px] flex justify-center items-center text-xs gap-1 text-white bg-[#00A5EF]`}
                    >
                        <><div className="h-3 w-3"><LinkIcon /></div> Link</>
                    </button>
                </div>

            </div>
            <span className='text-[#939393] font-medium'>Serial Number : {data.userMinterId}</span>

            <div className={'mt-4 border-y  border-y-[#D5D5D5] py-5'}>
                <div className="grid grid-cols-12 gap-4">
                    <div className=" col-span-12 md:col-span-7 flex flex-col gap-2">
                        <span className='text-[#616161] font-medium'>Rewards Generated</span>
                        <span className='text-[#1B9FDA] text-xl font-medium'>{minterRewardGeneratedData?.totalRewardGenerated?.total}</span>
                        <div className=' py-3'>
                            {/* border-t border-t-[#D5D5D5] */}
                            <div className='flex flex-col  md:flex-row md:gap-8 gap-3'>
                                {data.tokensDetail.map((token, index) => {
                                    return (
                                        <div className='flex justify-between items-end gap-3' key={index}>
                                            <div className='flex flex-col '>
                                                <span className='text-[#616161] font-medium'>{token.tokenName}</span>
                                                <span className='text-[#121212] text-lg font-medium'>{token.tokenAmount}</span>
                                            </div>
                                            {hardwareClaimLoading && claimIndex === index ? <div className="flex justify-center items-center flex-1 w-full">
                                                <div className="justify-center items-center self-center">
                                                    <div className="flex flex-col gap-5">
                                                        <Spinner className="animate-spin h-6" />
                                                    </div>
                                                </div>
                                            </div> : <button
                                                onClick={() => {
                                                    setClaimIndex(index)
                                                    token.tokenAmount && hardwareGeneratedRewardClaimfunc()
                                                }}
                                                    disabled={(hardwareClaimLoading && claimIndex === index )|| !token.tokenAmount}
                                                type="button"
                                                className={`px-4 h-8 rounded-[16px] flex justify-center items-center text-sm gap-1 border border-[#00A5EF] text-[#00A5EF] bg-white
                                            ${(hardwareClaimLoading && claimIndex === index) || !token.tokenAmount ? '!bg-[#C0D5DF] cursor-not-allowed border border-[#D5D5D5] text-white' : ''} `}
                                            >
                                                Claim
                                            </button>}   
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    <TotalLinked lockNft={minterRewardGeneratedData.lockNft} maxNft={minterRewardGeneratedData.maxNftHold} stakedPercentage={minterRewardGeneratedData.stakedPercentage} />
                    
                </div>
            </div>

            <div className="col-span-12 md:col-span-12 border border-[#D9E4F1] px-3 py-4 my-4 rounded-lg">
                <div className='flex-col md:flex-row flex gap-2 justify-between items-start  md:items-center'>
                    <span className='text-[#616161] text-lg font-medium'>Minter’s Daily Activity</span>
                    <div className="flex gap-2">
                        <div className="flex gap-2">
                            {/* Month Dropdown */}
                            <select
                                value={selectedMonth}
                                onChange={(e) => setSelectedMonth(Number(e.target.value))}
                                className="border border-[#D5DBE0] rounded-[10px] p-2 text-[#616161] "
                            >
                                {monthsData.map((month, index) => (
                                    <option key={index} value={index} className="text-[#616161]">
                                        {month}
                                    </option>
                                ))}
                            </select>

                            {/* Year Dropdown */}
                            <select
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(Number(e.target.value))}
                                className="border border-[#D5DBE0] rounded-[10px] p-2 text-[#616161] "
                            >
                                {yearsData.map((year) => (
                                    <option key={year} value={year} className="text-[#616161]">
                                        {year}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-3 mt-4">
                    <div className='flex gap-6'>
                        <div className='flex gap-2'>
                            <div className='bg-[#E7E7E7] w-4 h-[14px] rounded-[2px]'></div>
                            <span className='text-[#939393] text-xs font-medium'>Non Eligible</span>
                        </div>
                        <div className='flex gap-2'>
                            <div className='bg-[#D4FBB9] w-4 h-[14px] rounded-[2px]'></div>
                            <span className='text-[#939393] text-xs font-medium'>Eligible</span>
                        </div>
                    </div>
                    {dailyActivityLoading ? <div className="flex justify-center items-center flex-1  h-64 w-full">
                        <div className="justify-center items-center self-center">
                            <div className="flex flex-col gap-5">
                                <Spinner className="animate-spin h-6" />
                            </div>
                        </div>
                    </div> : <div className="flex flex-wrap gap-3 w-full ">
                        {Array.from({ length: daysInMonth }, (_, i) => i + 1)?.map((day: any) => (

                            <div key={day} className={`border flex justify-center items-center rounded h-7 w-7 ${true ? ' bg-[#D4FBB9]' : ' bg-[#E7E7E7]'}
                                     text-black text-sm font-medium`}>
                                {day}

                            </div>

                        ))}
                    </div>}

                </div>
            </div>

            <div className=' border-t border-t-[#D5D5D5] py-5 flex flex-col'>
                <div className='flex justify-between items-center gap-2'>
                    <span className='text-[#616161] text-lg font-medium'>Linked License</span>
                    <span
                         onClick={() => {
              navigate('/defi-360/cloudk/reward-history');
            }}
                        className='text-xs text-[#292929] underline cursor-pointer'>View Rewards History</span>

                </div>

                <div className='flex justify-start gap-4 mt-3 overflow-hidden'>
                    <SimpleSlider
                        onSwiper={(swiper) => {
                            // sliderRef.current = swiper;
                        }}
                        // onSlideChange={(swiper) => {
                        //     setCurrentIndex(swiper.activeIndex);
                        //     setIsAtEnd(swiper.isEnd); // Update end status here
                        // }}
                        className="w-full"
                        spaceBetween={8}
                        slidesPerView={'auto'}
                        modules={[Navigation]}
                        navigation={false} // Disable default Swiper navigation
                        breakpoints={{
                            768: {
                                slidesPerView: 'auto',
                            },
                        }}
                    >
                        {minterLinkedLicenses?.list?.map((licience:any,index:number)=>{
                        return(
                            <SwiperSlide key={index} className="!w-[220px] h-[271px] cursor-grab">

                            <div key={index} className='border border-[#D9E4F1] rounded  relative p-3 flex flex-col gap-3 '>
                                <div className='w-full flex justify-center mt-3'>
                                        <img src={licience.licenseImage?.link} alt={licience.identifier} className='h-[87px] w-[63px]' />
                                </div>
                                <div className='w-full flex justify-center items-center flex-col gap-1'>
                                    <span className='font-medium text-[#121212] text-xs'>
                                            {licience.licenceName} 
                                    </span>
                                        <span className='text-xs text-[#939393]'>{licience.identifier}</span>
                                </div>
                                <div className='flex justify-between'>
                                    <div className='flex flex-col gap-1'>
                                        <span className='font-medium text-[#121212] text-xs'>
                                            {licience.availableBalance}
                                        </span>
                                        <span className='text-xs text-[#939393]'>Available {licience.availableBalanceToken}</span>
                                </div>
                                    <div className='flex flex-col gap-1'>
                                        <span className='font-medium text-[#121212] text-xs'>
                                                {licience.lockNft}
                                        </span>
                                        <span className='text-xs text-[#939393]'># of NFTs</span>
                                    </div>
                                </div>
                                {deLinkLoading && deLinkIndex === index ? <div className="flex justify-center items-center flex-1  w-full">
                                    <div className="justify-center items-center self-center">
                                        <div className="flex flex-col gap-5">
                                            <Spinner className="animate-spin h-6" />
                                        </div>
                                    </div>
                                </div> : <button
                                    onClick={() => {
                                                if (licience.type === 'LINKED' ){
                                                setDeLinkIndex(index)
                                                const data = { id: 123 }
                                                deLinkLicenseFunc(data)
                                            }
                                    }}
                                            disabled={licience.type !== 'LINKED'   || (deLinkLoading && deLinkIndex === index)}
                                    type="button"
                                    className={`p-4 h-6 rounded-[16px] flex justify-center items-center text-xs gap-1 text-[#FFFFFF]  
                                        ${licience.type === 'LINKED' && (!deLinkLoading ) ? 'bg-[#00A5EF]' : 'bg-[#C0D5DF] cursor-not-allowed' } `}
                                >
                                    De-link
                                </button>}
                              
                            </div>
                            </SwiperSlide>
                        )
                    })}
                    </SimpleSlider>
                </div>
            </div>
          
            <div className=' border-t border-t-[#D5D5D5] py-5 flex flex-col mt-2'>

                <CloudKTable
                    rows={rows}
                    title={'Hardware Transaction History'}
                    columns={columns}
                    loading={machineRewardHistoryLoading}
                    filters={[
                        <CloudKSelect options={options} placeholder={'Transaction Type'}
                            onChange={handleTypeChange} // Handle the option change
                            selectedOption={filters.type}
                        />,
                        <CloudKSelect options={options} placeholder={'All Time'}
                            onChange={handleTimeChange} // Handle the option change
                            selectedOption={filters.time}
                        />,
                        <button
                            className={`${resetDisable ? 'cursor-not-allowed text-primaryGrey' : 'text-primary'} text-xs font-medium h-10`}
                            type="button"
                            disabled={resetDisable}
                            onClick={() => {
                                setResetFilter(true);
                                setFilters({
                                    limit: 10,
                                    page: 1,
                                    licenseId: null,
                                    licensesStatus: null,
                                    fromDate: null,
                                    toDate: null,
                                });

                            }}
                        >
                            Reset
                        </button>
                    ]}
                    // paginationContainerStyle={'!justify-end'}
                    // navigationText={`View All Rewards History`}
                    // navigationStyle={``}
                    // navigationShow={true}
                    // navigationContainerStyle={``}
                    footerNavigation={{
                        text:"View All Rewards History",
                        url:'/defi-360/cloudk/reward-history'
                    }}
                />
            </div>
        </div>
    )
}

export default HardwareDetailModal
