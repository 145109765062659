import React, { useEffect, useRef, useState } from 'react';
import CardLayout from '../../../../../components/Cards/CardLayout';
import { ReactComponent as ArrowRight } from '../../../../../SVGIcons/arrow-right.svg';
import { useNavigate } from 'react-router-dom';
import { licenseImg, upIcon } from '../../../../../images/cloudk';
import { ReactComponent as LinkIcon } from '../../../../../SVGIcons/cloud/link.svg';

import SimpleSlider from '../../../../../components/Slider/CardSlider';
import { Navigation } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import GlobalModal from '../../../../../components/Global/GlobalModal';
import useModalHook from '../../../../../hooks/useModalHook';
import AddMintingToken from './AddMintingToken/AddMintingToken';
import { useSelector } from 'react-redux';
import { ReactComponent as Spinner } from '../../../../../SVGIcons/spinner.svg';
import { selectCloudSlice } from '../../../../../store/cloudk/cloudkSlice';
import { useCloudkActions } from '../../../../../store/cloudk/cloudkAction';

const data = [
  {
    upgradeAble: true,
    icon: licenseImg,
    name: 'LayerK XSTK-220',
    boostValue: 'x2',
    isActive: true,
    lock: false,
    disable: false,
  },
  {
    upgradeAble: false,
    icon: licenseImg,
    name: 'LayerK XSTK-221',
    boostValue: 'x2',
    isActive: false,
    lock: true,
    disable: false,
  },
  {
    upgradeAble: true,
    icon: licenseImg,
    name: 'LayerK XSTK-222',
    boostValue: 'x2',
    isActive: true,
    lock: false,
    disable: true,
  },
  {
    upgradeAble: true,
    icon: licenseImg,
    name: 'LayerK XSTK-223',
    boostValue: 'x2',
    isActive: true,
    lock: false,
    disable: false,
  },
  {
    upgradeAble: false,
    icon: licenseImg,
    name: 'LayerK XSTK-224',
    boostValue: 'x2',
    isActive: false,
    lock: true,
    disable: false,
  },
  {
    upgradeAble: true,
    icon: licenseImg,
    name: 'LayerK XSTK-225',
    boostValue: 'x2',
    isActive: true,
    lock: false,
    disable: true,
  },
  {
    upgradeAble: true,
    icon: licenseImg,
    name: 'LayerK XSTK-226',
    boostValue: 'x2',
    isActive: true,
    lock: false,
    disable: true,
  },
  {
    upgradeAble: true,
    icon: licenseImg,
    name: 'LayerK XSTK-227',
    boostValue: 'x2',
    isActive: true,
    lock: false,
    disable: true,
  },
];
const AllLicenses = () => {
  const navigate = useNavigate();
  const { open, handleClose, handleOpen } = useModalHook();
    const { allLicienceLoading, allLiciencesData } = useSelector(selectCloudSlice)
    const { getAllLicenses } = useCloudkActions()
  const sliderRef = useRef<any>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const handlePrev = () => {
    if (sliderRef.current && currentIndex > 0) {
      sliderRef.current.slidePrev();
      setCurrentIndex(currentIndex - 1);
    }
  };
  const handleNext = () => {
    if (sliderRef.current && !isAtEnd) {
      sliderRef.current.slideNext();
      setCurrentIndex(currentIndex + 1);
    }
  };
  useEffect(()=>{
      getAllLicenses()
  },[])
  return (
    <CardLayout className=" col-span-12  p-5 flex flex-col justify-between gap-2 !shadow-boxShadow">
      <section className="flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <h1 className="text-secondaryBlack font-medium sm:text-lg text-sm">
            Licenses
          </h1>
          <div
            className=" top-5 justify-end right-5 bottom-[-6px] md:bottom-5 md:right-[10px] gap-1 flex cursor-pointer"
            onClick={() => {
              navigate('/defi-360/cloudk/Licenses');
            }}
          >
            <span className="text-sm font-normal">View All</span>
            <div className="h-5 w-5">
              <ArrowRight />
            </div>
          </div>
        </div>
      </section>

      <div className="relative">
        <div className="flex gap-2 overflow-hidden">
                  {allLicienceLoading ? <div className="flex justify-center items-center flex-1  h-64 w-full">
                      <div className="justify-center items-center self-center">
                          <div className="flex flex-col gap-5">
                              <Spinner className="animate-spin h-6" />
                          </div>
                      </div>
                  </div> : 
                      <SimpleSlider
                          onSwiper={(swiper) => {
                              sliderRef.current = swiper;
                          }}
                          onSlideChange={(swiper) => {
                              setCurrentIndex(swiper.activeIndex);
                              setIsAtEnd(swiper.isEnd); // Update end status here
                          }}
                          className="w-full"
                          spaceBetween={8}
                          slidesPerView={'auto'}
                          modules={[Navigation]}
                          navigation={false} // Disable default Swiper navigation
                          breakpoints={{
                              768: {
                                  slidesPerView: 'auto',
                              },
                          }}
                      >
                          {data?.map((item, index) => (
                            <SwiperSlide key={index} className="!w-52 !h-64 cursor-grab">
                                  <div className="border border-[#D9E4F1] rounded-lg w-52 h-64 relative p-3 flex flex-col gap-6">
                                      {item.upgradeAble && (
                                          <div className="shadow-lg p-1 absolute right-3 top-3 border border-[#00A5EF] rounded-xl bg-white flex justify-center items-center">
                                              <div className="h-[9px] w-[9px] mr-[2px]">
                                                  <img
                                                      src={upIcon}
                                                      alt="icon"
                                                      className="h-full w-full"
                                                  />
                                              </div>
                                              <span className="font-medium text-[#00A5EF] text-[9px]">
                                                  Upgrade
                                              </span>
                                          </div>
                                      )}
                                      <div className="w-full flex justify-center mt-3">
                                          <img
                                              src={item.icon}
                                              alt="machine-image"
                                              className="h-[120px] w-[86px]"
                                          />
                                      </div>

                                      <div className="flex flex-col gap-2 w-full items-center">
                                          <div className="flex flex-col gap-2 justify-center items-center">
                                              <div className="flex gap-1 items-center">
                                                  <span className="font-medium text-[#121212]">
                                                      {item.name}{' '}
                                                      <span className="text-sm text-[#939393]">
                                                          {item.boostValue}
                                                      </span>
                                                  </span>
                                                  <div className="relative w-2 h-2 ml-1">
                                                      <span className="flex gap-2 h-2 w-2">
                                                          <span
                                                              className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${item.isActive ? 'bg-primaryGreen' : 'bg-primaryRed'}`}
                                                          ></span>
                                                          <span
                                                              className={`relative inline-flex rounded-full h-2 w-2 ${item.isActive ? 'bg-primaryGreen' : 'bg-primaryRed'}`}
                                                          ></span>
                                                      </span>
                                                  </div>
                                              </div>
                                          </div>

                                          <button
                                              onClick={() => {
                                                  !item.lock && handleOpen();
                                                  item.lock &&
                                                      navigate('/defi-360/cloudk/license-unlock');
                                              }}
                                              disabled={item.disable}
                                              type="button"
                                              className={`w-44 h-6 rounded-[16px] flex justify-center items-center text-xs gap-1 text-white ${!item.lock && item.disable ? 'bg-[#C0D5DF] cursor-not-allowed' : 'hover:bg-primary'} bg-[#00A5EF]`}
                                          >
                                              {item.lock ? (
                                                  <>Unlock</>
                                              ) : (
                                                  <>
                                                      <div className="h-3 w-3">
                                                          <LinkIcon />
                                                      </div>{' '}
                                                      Link
                                                  </>
                                              )}
                                          </button>
                                      </div>
                                  </div>
                              </SwiperSlide>
                          ))}
                      </SimpleSlider>
                  }
     
        </div>

        {/* Fade effect */}
        <div className="absolute z-10 top-0 right-0 h-full w-[10%] pointer-events-none bg-gradient-to-l from-white md:block hidden"></div>
      </div>
      <div className="md:hidden flex gap-4 mt-2">
        <button
          onClick={handlePrev}
          disabled={currentIndex === 0}
          className={` rounded-full ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'} transition`}
        >
          <IoIosArrowBack size={20} />
        </button>
        <button
          onClick={handleNext}
          disabled={isAtEnd}
          className={` rounded-full ${isAtEnd ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'} transition`}
        >
          <IoIosArrowForward size={20} />
        </button>
      </div>
      <GlobalModal
        isOpen={open}
        onClose={handleClose}
        size="3xl"
        childrenClass="!px-0 !py-0 small-scroll"
        title={'Add Utility Minting Token'}
        noHeader={false}
        headerClassName="!p-6  font-medium text-lg text-[#0E173F]"
      >
        <AddMintingToken />
      </GlobalModal>
    </CardLayout>
  );
};

export default AllLicenses;
