import {
  callUserInformation,
  setLogin,
  setLoginToken,
  setLogout,
  setUser,
  setUserState,
  updateLoading,
  updateUser,
  setResetPasswordLoading,
  setUserSlice,
  setShowCommunity,
} from './userSlice';
import { useDispatch } from 'react-redux';
import {
  ACCEPT_AGREEMENT,
  ACCOUNT_TYPE,
  CHANGE_EMAIL,
  CHECK_KYC,
  CONFIRM_EMAIL,
  FORGET_PASSWORD,
  FORGET_PASSWORD_REQUEST,
  GET_PROFILE,
  GET_REF_ID,
  GET_TOTP,
  GET_USER,
  LOGIN,
  LOGIN_CONFIRM,
  REGISTER,
  RETRY_USER_VERIFICATION,
  UPDATE_ADDRESS,
  UPDATE_NAME,
  UPDATE_PROFILE_PICTURE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SUBMIT_REFERRAL,
  CHNAGE_PASSWORD,
  RECENT_DEVICES,
  START_KYC,
  PASSWORD_CHANGE,
  RESEND_OTP,
  SIGNOUT_USER,
  SIGNOUT_FROM_ALL_DEVICE,
  UPDATE_USER_DATA,
  GET_ACIVE_MEMBERSHIP,
  GET_ACIVE_MEMBERSHIP_UPLINE,
  UPDATE_USER,
} from './../../utils/network/ApiEndpoints';
import { toast } from 'react-toastify';
import { TLogin, TSignup } from '../../components/types/auth/auth.types';
import { ApiResponse } from '../../components/interface/AxiosResponse.interface';
import {
  IChangePassword,
  IGetTOTPRequest,
  IGetTOTPResponse,
  IGetUserRequest,
  IPostTOTPRequest,
  IPostTOTPResponse,
  IResetPassword,
  IUserAcceptAgreementRequest,
  IUserAcceptAgreementResponse,
  IUserAccountTypeRequest,
  IUserAccountTypeResponse,
  IUserLoginConfirmRequest,
  IUserLoginConfirmResponse,
  IUserLoginResponse,
  IUserReferralAgreementRequest,
  IUserReferralAgreementResponse,
  IUserSignupRequest,
  IUserSignupResponse,
  OTP_METHOD,
  TUser,
} from '../../components/interface/auth/auth.interface';
import { AxiosRequestConfig } from 'axios';
import ApiRequestBID from '../../utils/network/ApiRequestBID';
import { TUpdateEmail } from '../../components/types/Profile/profile.type';
import {
  ChangePasswordConfirm,
  IEmailConfirm,
  IUPpdateAddress,
  IUpdateName,
  MessageResponse,
} from '../../components/interface/userProfile/userProfile.interface';
import { PAGES } from '../../utils/routers';
import { getAccessToken } from '../../utils/storage/protectedStorage';
import { store } from '..';
import { TUserMembershipStatus } from '../../utils/allConstants';
import { isDateExpired } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';

export const useUserActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = async (data: TLogin) => {
    dispatch(
      setUserState({
        loginLoading: true,
      })
    );
    localStorage.setItem('drawer', JSON.stringify(true));
    return await ApiRequestHomnifi()
      .request<AxiosRequestConfig<TLogin>, ApiResponse<IUserLoginResponse>>({
        method: 'POST',
        url: LOGIN,
        data,
      })
      .then((response) => {
        dispatch(
          setUserState({
            loginLoading: false,
          })
        );

        const { data } = response;
        dispatch(
          setLogin({
            accessToken: data.data.accessToken,
            loginToken: data.data.loginToken,
          })
        );
        navigate(PAGES.VERIFY_EMAIL, {
          state: {
            otpMethod: data.data.otpMethod,
            email: data.data.email,
          },
          replace: true,
        });
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setUserState({
            loginLoading: false,
          })
        );

        toast.error(error.data.message || 'Login failed');
        return error;
      });
  };

  const userSignup = async (data: TSignup) => {
    dispatch(
      setUserState({
        signupLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request<
        AxiosRequestConfig<IUserSignupRequest>,
        ApiResponse<IUserSignupResponse>
      >({
        method: 'POST',
        url: REGISTER,
        data,
      })
      .then((response) => {
        dispatch(
          setUserState({
            signupLoading: false,
          })
        );
        if (response.status) {
          navigate('/auth/signup-success');
        }

        return response;
      })
      .catch((error) => {
        dispatch(
          setUserState({
            signupLoading: false,
          })
        );

        toast.error(error.data.message || 'Sigup failed');

        return error;
      });
  };

  const updateAccountType = async ({
    accountType,
  }: {
    accountType: string;
  }) => {
    dispatch(
      setUserState({
        accountTypeLoading: true,
      })
    );
    return await ApiRequestBID()
      .request<
        AxiosRequestConfig<IUserAccountTypeRequest>,
        ApiResponse<IUserAccountTypeResponse>
      >({
        method: 'POST',
        url: ACCOUNT_TYPE,
        data: {
          accountType,
        },
      })
      .then((response) => {
        dispatch(
          setUserState({
            accountTypeLoading: false,
          })
        );
        dispatch(
          updateUser({
            accountType,
          })
        );
        return response;
      })
      .catch((error) => {
        dispatch(
          setUserState({
            accountTypeLoading: false,
          })
        );
        toast.error(error.data.message);

        return error;
      });
  };

  const otpConfirm = async (data: IUserLoginConfirmRequest) => {
    dispatch(
      setUserState({
        verifyOtpLoading: true,
      })
    );

    return await ApiRequestBID()
      .request<
        AxiosRequestConfig<IUserLoginConfirmRequest>,
        ApiResponse<IUserLoginConfirmResponse>
      >({
        method: 'POST',
        url: LOGIN_CONFIRM,
        data,
      })
      .then((response) => {
        console.log('response', response);

        dispatch(
          setUserState({
            verifyOtpLoading: false,
          })
        );
        const { data } = response;

        dispatch(
          setLoginToken({
            user: data.user,
          })
        );

        dispatch(
          setLogin({
            loginToken: data.token,
            accessToken: getAccessToken(),
          })
        );

        dispatch(
          setShowCommunity({
            builderGeneral: data?.user?.builderGeneral,
            builderReferral: data?.user?.builderReferral,
            baseReferral: data?.user?.baseReferral,
          })
        );
        
        if (!data.user.accountType) {
          navigate(PAGES.ACCOUNT_TYPE, {
            replace: true,
          });
          return { status: true, response };
        }
        if (!data.user.termsAccepted) {
          navigate(PAGES.TERMS, {
            replace: true,
          });
          return { status: true, response };
        }
        if (data.user.otpMethod === OTP_METHOD.EMAIL) {
          navigate(PAGES.GOOGLE_AUTH, {
            replace: true,
          });
          return { status: true, response };
        }
        if (!data.user.referralSubmitted) {
          navigate(PAGES.REFERRAL_LINK, {
            replace: true,
          });
          return { status: true, response };
        }

        // navigate(PAGES.DASHBOARD);
        return { status: true, response };
      })
      .catch((error) => {
        toast.error(error?.data?.meesage || error?.response?.data?.otp?.[0]);
        dispatch(setUserState({ verifyOtpLoading: false }));
        return { status: false, response: error };
      });
  };

  const otpResend = async (data: any) => {
    dispatch(
      setUserState({
        resendOtpLoading: true,
      })
    );
    return await ApiRequestBID()
      .request<AxiosRequestConfig<any>, ApiResponse<any>>({
        method: 'POST',
        url: RESEND_OTP,
        data: data,
        headers: {
          Authorization: null,
        },
      })
      .then((response) => {
        dispatch(
          setUserState({
            resendOtpLoading: false,
          })
        );
        // const { data } = response;
        toast.success('Otp sent successfully');

        // navigate(PAGES.DASHBOARD);
        return { status: true, response };
      })
      .catch((error) => {
        toast.error(error.data.message);
        dispatch(setUserState({ resendOtpLoading: false }));
        return { status: false, response: error };
      });
  };

  const userAcceptAgreement = async () => {
    dispatch(
      setUserState({
        termsAcceptLoading: true,
      })
    );
    return await ApiRequestBID()
      .request<
        AxiosRequestConfig<IUserAcceptAgreementRequest>,
        ApiResponse<IUserAcceptAgreementResponse>
      >({
        method: 'POST',
        url: ACCEPT_AGREEMENT,
      })
      .then((response) => {
        dispatch(
          setUserState({
            termsAcceptLoading: false,
          })
        );
        dispatch(updateUser({ termsAccepted: true }));

        return response;
      })
      .catch((error) => {
        dispatch(
          setUserState({
            termsAcceptLoading: false,
          })
        );
        toast.error(error.data.message);
        return error;
      });
  };

  const getTOtp = async () => {
    dispatch(
      setUserState({
        getTOtpLoading: true,
      })
    );
    return await ApiRequestBID()
      .request<
        AxiosRequestConfig<IGetTOTPRequest>,
        ApiResponse<IGetTOTPResponse>
      >({
        method: 'GET',
        url: GET_TOTP,
      })
      .then((response) => {
        dispatch(
          setUserState({
            getTOtpLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setUserState({
            getTOtpLoading: false,
          })
        );
        if (error?.data?.message === 'OTP method already set') {
          navigate(PAGES.REFERRAL_LINK, {
            replace: true,
          });
        }
        toast.error(error.data.message);
        return error;
      });
  };

  const postOtp = async (data: IGetTOTPRequest) => {
    dispatch(
      setUserState({
        getTOtpLoading: true,
      })
    );
    return await ApiRequestBID()
      .request<
        AxiosRequestConfig<IPostTOTPRequest>,
        ApiResponse<IPostTOTPResponse>
      >({
        method: 'POST',
        url: GET_TOTP,
        data,
      })
      .then((response) => {
        dispatch(
          setUserState({
            getTOtpLoading: false,
          })
        );
        dispatch(updateUser({ otpMethod: 'TOTP' }));
        return response;
      })
      .catch((error) => {
        dispatch(
          setUserState({
            getTOtpLoading: false,
          })
        );
        toast.error(error?.response?.data?.totpOtp[0]);
        return error?.response?.data;
      });
  };

  const getUser = async () => {
    dispatch(
      setUserState({
        getUserLoading: true,
      })
    );
    return await ApiRequestBID()
      .request<
        // 3838421215
        AxiosRequestConfig<IGetUserRequest>,
        ApiResponse<TUser>
      >({
        method: 'GET',
        url: GET_USER,
      })
      .then((response) => {
        dispatch(
          setUserState({
            getUserLoading: false,
          })
        );
        const user = response.data;

        const membershipStatus =
          user?.membershipExpiry && isDateExpired(user?.membershipExpiry)
            ? TUserMembershipStatus.EXPIRED
            : user?.isActiveMembership
              ? TUserMembershipStatus.ACTIVE
              : TUserMembershipStatus.NO_MEMBERSHIP;

        // const editUser = {
        //   ...user,
        //   membershipExpiry: '2024-07-18T16:07:28.615143Z',
        //   isActiveMembership: true,
        // };
        dispatch(setUser({ ...user, membershipStatus }));

        // const modify = {
        //   ...response.data,
        // kycApproved: false,
        // isActiveMembership: true,
        // membershipExpiry: '2025-01-02T17:23:32.979591Z',
        // };

        // dispatch(setUserState({
        //   profileCompletePercentage
        // }))

        return response;
      })
      .catch((error) => {
        dispatch(
          setUserState({
            getUserLoading: false,
          })
        );
        console.error(error);

        toast.error(error?.data?.message);
        return error;
      });
  };

  const submitReferral = async (data: IUserReferralAgreementRequest) => {
    dispatch(
      setUserState({
        submitReferralLoading: true,
      })
    );
    return await ApiRequestBID()
      .request<
        AxiosRequestConfig<IUserReferralAgreementRequest>,
        ApiResponse<IUserReferralAgreementResponse>
      >({
        method: 'POST',
        url: SUBMIT_REFERRAL,
        data,
      })
      .then((response) => {
        dispatch(
          setUserState({
            submitReferralLoading: false,
          })
        );
        dispatch(updateUser({ referralSubmitted: true }));
        navigate(PAGES.DASHBOARD, {
          replace: true,
        });

        return response;
      })
      .catch((error) => {
        dispatch(setUserState({ submitReferralLoading: false }));
        if (error.data.referralUpline[0]) {
          toast.error(error.data.referralUpline[0]);
        } else {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const getReferral = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: GET_REF_ID,
      })
      .then((response: any) => {
        // const { data } = response;

        return response;
      })
      .catch((error: any) => error);
  };

  const getUserProfiles = async () => {
    dispatch(callUserInformation());

    return await ApiRequestBID()
      .request({
        method: 'GET',
        url: GET_PROFILE,
      })
      .then((response: any) => {
        const profile = response.data.data;

        dispatch(setUser({ user: profile }));
        return response;
      })
      .catch((error: any) => error);
  };

  const retryVerification = async () => {
    dispatch(updateLoading({ key: 'retryKycLoading', value: true }));
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: RETRY_USER_VERIFICATION,
      })
      .then((response: any) => {
        dispatch(updateLoading({ key: 'retryKycLoading', value: false }));
        getUserProfiles();
        return response;
      })
      .catch((error: any) => {
        dispatch(updateLoading({ key: 'retryKycLoading', value: false }));
        return error;
      });
  };

  const userLogout = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: SIGNOUT_USER,
      })
      .then((response: any) => {
        dispatch(setLogout());
        return response;
      })
      .catch((error: any) => {
        return error;
      });
  };

  const userForgetPasswordRequest = async (email: string) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: FORGET_PASSWORD_REQUEST,
        data: {
          email,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error);
  };

  const userForgetPassword = async ({
    newPassword,
    confirmPassword,
    token,
  }: {
    newPassword: any;
    confirmPassword: any;
    token: any;
  }) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: FORGET_PASSWORD,
        data: {
          newPassword,
          confirmPassword,
          token,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error);
  };

  const updateProfilePicture = async (data: any) => {
    return await ApiRequestBID()
      .request({
        data: data,
        method: 'POST',
        url: UPDATE_PROFILE_PICTURE,
      })
      .then((response: any) => {
        // const { user } = response;
        getUser();
        return response;
      })
      .catch((error: any) => error);
  };

  const updateUserEmail = async (data: TUpdateEmail) => {
    dispatch(
      setUserState({
        userEmailLoading: true,
      })
    );
    return await ApiRequestBID()
      .request<AxiosRequestConfig<TUpdateEmail>, ApiResponse<any>>({
        method: 'POST',
        url: CHANGE_EMAIL,
        data,
      })
      .then((response) => {
        dispatch(
          setUserState({
            userEmailLoading: false,
          })
        );
        if (
          response.status === 200 &&
          response.data.message === 'Email change request created'
        ) {
          toast.success('Email OTP sent Sucessfully');
        } else {
          toast.success(response.data.message);
        }
        return response;
      })
      .catch((error: any) => {
        const response = error?.response;
        toast.error(
          error?.data?.message ||
            (response?.data?.email && response?.data?.email[0])
        );
        dispatch(
          setUserState({
            userEmailLoading: false,
          })
        );
        return error;
      });
  };
  const updateEmailOtpConfirm = async (data: IEmailConfirm, email: any) => {
    dispatch(
      setUserState({
        verifyOtpLoading: true,
      })
    );
    return await ApiRequestBID()
      .request<AxiosRequestConfig<IEmailConfirm>, ApiResponse<MessageResponse>>(
        {
          method: 'POST',
          url: CONFIRM_EMAIL,
          data,
        }
      )
      .then((response) => {
        dispatch(
          setUserState({
            verifyOtpLoading: false,
          })
        );
        dispatch(updateUser({ email: email }));

        toast.success(response.data.message);

        return { status: true, data: response };
      })
      .catch((error) => {
        toast.error(error?.data?.message || 'In valid OTP ');
        dispatch(setUserState({ verifyOtpLoading: false }));
        return { status: false, data: error };
      });
  };

  const checkUserKYC = async (id: any) => {
    return ApiRequestBID()
      .request({
        method: 'GET',
        url: CHECK_KYC,
      })
      .then((response: any) => {
        // const { user } = response.data;
        // fetchLatestUserData();
        return response;
      })
      .catch((error: any) => error);
  };

  const startUserKYC = async () => {
    dispatch(
      setUserState({
        kycStartLoading: true,
      })
    );
    return ApiRequestBID()
      .request({
        method: 'POST',
        url: START_KYC,
      })
      .then((response: any) => {
        // const { user } = response.data;
        dispatch(
          setUserState({
            kycStartLoading: false,
          })
        );
        // fetchLatestUserData();
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setUserState({
            kycStartLoading: false,
          })
        );
        return error;
      });
  };
  const getRecentDevices = async () => {
    dispatch(setUserState({ recentDevicesLoading: true }));
    return ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: RECENT_DEVICES,
      })
      .then((response: any) => {
        // const { user } = response.data.data;
        dispatch(setUserState({ recentDevices: response.data.data.list }));
        dispatch(setUserState({ recentDevicesLoading: false }));
        // fetchLatestUserData();
        return response;
      })
      .catch((error: any) => {
        dispatch(setUserState({ recentDevicesLoading: false }));
        return error;
      });
  };

  const updatUserAddress = async (data: IUPpdateAddress) => {
    const { address, city, country, zipCode } = data;
    dispatch(
      setUserState({
        addressLoading: true,
      })
    );
    return await ApiRequestBID()
      .request<
        AxiosRequestConfig<IUPpdateAddress>,
        ApiResponse<MessageResponse>
      >({
        method: 'PUT',
        url: UPDATE_ADDRESS,
        data,
      })
      .then((response) => {
        dispatch(
          setUserState({
            addressLoading: false,
          })
        );
        if (response.data.message) {
          dispatch(updateUser({ address, city, country, zipCode }));
          dispatch(
            setUserSlice({
              isProfileCompletePercentage: false,
            })
          );
        }
        toast.success(response?.data?.message);

        return { status: true, data: response };
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(setUserState({ addressLoading: false }));
        return { status: false, data: error };
      });
  };

  const updateUserName = async (data: IUpdateName) => {
    const { firstName, lastName } = data;
    dispatch(
      setUserState({
        userNameLoading: true,
      })
    );
    return await ApiRequestBID()
      .request<AxiosRequestConfig<IUpdateName>, ApiResponse<MessageResponse>>({
        method: 'POST',
        url: UPDATE_NAME,
        data,
      })
      .then((response) => {
        dispatch(
          setUserState({
            userNameLoading: false,
          })
        );
        if (response.data.message) {
          dispatch(updateUser({ firstName, lastName }));

          dispatch(
            setUserSlice({
              isProfileCompletePercentage: false,
            })
          );
        }
        getUser();
        toast.success(response?.data?.message);
        return { status: true, data: response };
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(setUserState({ userNameLoading: false }));
        return { status: false, data: error };
      });
  };

  const updateUserDetails = async (data: any) => {
    dispatch(
      setUserState({
        userEmailLoading: true,
      })
    );
    return await ApiRequestHomnifi()
      .request<AxiosRequestConfig<IUpdateName>, ApiResponse<MessageResponse>>({
        method: 'PUT',
        url: UPDATE_USER,
        data,
      })
      .then((response) => {
        dispatch(
          setUserState({
            userEmailLoading: false,
          })
        );
        if (response.data.message) {
          dispatch(
            setUserState({
              userEmailLoading: false,
            })
          );
        }
        return { status: true, data: response };
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        dispatch(setUserState({ userEmailLoading: false }));
        return { status: false, data: error };
      });
  };

  const forgotPassword = async (data: any) => {
    dispatch(setResetPasswordLoading(true));
    return ApiRequestBID()
      .request({
        method: 'Post',
        url: FORGOT_PASSWORD,
        data,
      })
      .then((response: any) => {
        dispatch(setResetPasswordLoading(false));
        return response;
      })
      .catch((error: any) => {
        dispatch(setResetPasswordLoading(false));
        return error;
      });
  };

  const changePasswordConfirm = async (data: ChangePasswordConfirm) => {
    dispatch(setUserSlice({ changePasswordConfirmLoading: true }));
    return ApiRequestBID()
      .request({
        method: 'POST',
        url: PASSWORD_CHANGE,
        data,
      })
      .then((response: any) => {
        dispatch(setUserSlice({ changePasswordConfirmLoading: false }));

        toast.success(response?.data.message);

        return response;
      })
      .catch((error: any) => {
        dispatch(setUserSlice({ changePasswordConfirmLoading: false }));
        return error;
      });
  };

  const resetPassword = async (data: IResetPassword) => {
    dispatch(setResetPasswordLoading(true));
    delete data.confirmPassword;
    return ApiRequestBID()
      .request({
        method: 'Post',
        url: RESET_PASSWORD,
        data,
      })
      .then((response: any) => {
        dispatch(setResetPasswordLoading(false));
        return response;
      })
      .catch((error: Error) => {
        toast.error(error.message);
        dispatch(setResetPasswordLoading(false));
        return error;
      });
  };

  const changePassword = async (data: IChangePassword) => {
    dispatch(setUserState({ changePasswordLoading: true }));

    return ApiRequestBID()
      .request({
        method: 'Post',
        url: CHNAGE_PASSWORD,
        data,
      })
      .then((response: any) => {
        dispatch(setUserState({ changePasswordLoading: false }));
        return { status: true, ...response };
      })
      .catch((error: any) => {
        dispatch(setUserState({ changePasswordLoading: false }));
        return { status: false, ...error };
      });
  };

  const signOutFromAllDevice = async (
    token: string,
    type: 'reset-password' | 'logout',
    update?: boolean
  ) => {
    let url = '';

    if (type === 'reset-password') {
      url = `${SIGNOUT_FROM_ALL_DEVICE}?token=${token}`;
    } else if (type === 'logout') {
      url = `${SIGNOUT_FROM_ALL_DEVICE}?accessToken=${token}`;
    }

    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url,
      })
      .then((response: any) => {
        if (!update) dispatch(setLogout());
        return response;
      })
      .catch((error: Error) => {
        toast.error(error.message);
        return error;
      });
  };

  const updateUserData = async (user: TUser) => {
    return ApiRequestHomnifi()
      .request({
        method: 'PUT',
        url: UPDATE_USER_DATA,
        data: {
          username: user.username,
          firstName: user.firstName,
          lastName: user.lastName,
          profilePicture: user.profilePicture,
          dateJoined: user.dateJoined,
        },
      })
      .then((response: any) => response)
      .catch((error: any) => error);
  };

  const getActiveMembershipStats = async () => {
    dispatch(setUserState({ referralsLoading: true }));

    return ApiRequestBID()
      .request({
        method: 'GET',
        url: GET_ACIVE_MEMBERSHIP,
      })
      .then((response) => {
        dispatch(
          setUserState({
            referrals: response.data,
          })
        );
        dispatch(setUserState({ referralsLoading: false }));
        return response;
      })
      .catch((error) => {
        dispatch(setUserState({ referralsLoading: false }));
        return error;
      });
  };

  const getActiveMembershipUpline = async () => {
    dispatch(setUserState({ referralsUplineLoading: true }));
    return ApiRequestBID()
      .request({
        method: 'GET',
        url: GET_ACIVE_MEMBERSHIP_UPLINE,
      })
      .then((response) => {
        dispatch(
          setUserState({
            referrals: { ...store.getState().user.referrals, ...response.data },
          })
        );
        dispatch(setUserState({ referralsUplineLoading: false }));
        return response;
      })
      .catch((error) => {
        dispatch(setUserState({ referralsUplineLoading: false }));
        return error;
      });
  };

  return {
    userLogin,
    userLogout,
    userSignup,
    updateAccountType,
    getReferral,
    userForgetPasswordRequest,
    userForgetPassword,
    updateProfilePicture,
    getUserProfiles,
    retryVerification,
    userAcceptAgreement,
    otpConfirm,
    getTOtp,
    updateUserEmail,
    updateEmailOtpConfirm,
    updatUserAddress,
    getUser,
    submitReferral,
    checkUserKYC,
    postOtp,
    updateUserName,
    forgotPassword,
    resetPassword,
    changePassword,
    getRecentDevices,
    startUserKYC,
    changePasswordConfirm,
    otpResend,
    signOutFromAllDevice,
    updateUserData,
    getActiveMembershipStats,
    getActiveMembershipUpline,
    updateUserDetails,
  };
};
