/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useSocketIO from '../hooks/socketIO/useSocketIO';
import useUserCheck from '../hooks/useUserCheck';
import { selectDrawer } from '../store/drawer/drawerSlice';
import { useGeneralActions } from '../store/general/generalActions';
import {
  selectNewsletterModal,
  setNewsletterModal,
} from '../store/newsLetter/newsLetterSlice';
import { selectSocketIO } from '../store/socketIO/socketIOSlice';
import { useUserActions } from '../store/user/userActions';
import { checkPercentage } from '../store/user/userSlice';
import { PLATFORM_NAME, SOCKET_BASE_URL } from '../utils/constants';
import { PAGES } from '../utils/routers';
import ScrollToTop from '../utils/scrollToTop';
import BottomBar from './common/BottomBar';
import Navbar from './common/Navbar';
import Sidebar from './common/Sidebar';
import GlobalModal from './Global/GlobalModal';
import PrelineInit from './PrelineInit';
import { ReactComponent as Spinner } from '../SVGIcons/spinner.svg';
import { useCloudActions } from '../store/Cloudk2/cloudk2Actions';

export default function Layout() {
  const { user } = useUserCheck();

  if (!user) {
    return <Navigate to={PAGES.LOGIN} replace />;
  }

  return <MainLayout />;
}

const MainLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const toggleDrawer = useSelector(selectDrawer);
  const { socket } = useSelector(selectSocketIO);
  const { user, checkLayoutValidation, fetchUser } = useUserCheck();
  const { GetConvertedPrice } = useGeneralActions();
  const [signoutLoading, setSignoutLoading] = useState(false);
  const { updateUserData, userLogout } = useUserActions();
  const isNewsletterModal = useSelector(selectNewsletterModal);
  const { getBurnUserStatus } = useCloudActions();

  const { establishSocketConnection } = useSocketIO(SOCKET_BASE_URL);

  const fetchBurnStatusApi = async () => {
    await getBurnUserStatus();
  };

  useEffect(() => {
    if (socket) return;
    establishSocketConnection();
  }, [socket]);

  useEffect(() => {
    checkLayoutValidation();
    fetchBurnStatusApi();
  }, [user]);

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(checkPercentage());
      GetConvertedPrice();

      if (
        !localStorage.getItem('homnifi-username') ||
        !localStorage.getItem('homnifi-dateJoined')
      ) {
        updateUserData(user);
      }
    }
  }, [user]);

  const handleLogout = async () => {
    setSignoutLoading(true);
    await userLogout();
    setSignoutLoading(false);
  };
  return (
    <PrelineInit>
      <section
        className={`relative ${user?.isImpersonateMode ? 'border-4 border-primary' : ''}`}
      >
        {user?.isImpersonateMode && (
          <div className="text-center p-2 bg-primary text-white sticky top-0 z-[1000] flex items-center justify-center ">
            You are in Impersonate Mode
            <button
              className="bg-white px-4 py-0.5 text-sm rounded-md ms-3 text-primary font-medium"
              onClick={handleLogout}
            >
              {signoutLoading ? (
                <Spinner className="h-5 animate-spin" />
              ) : (
                'Stop'
              )}
            </button>
          </div>
        )}
        <Navbar drawer={toggleDrawer} />
        <div className="flex justify-center w-full">
          <Sidebar drawer={toggleDrawer} />
          <div
            className={`min-h-screen w-full overflow-hidden ${location.pathname === '/' ? 'bg-white' : 'px-4 md:px-12 sm:px-8 md:pt-10 md:pb-10 pt-8 pb-4 sm:pb-36 bg-pageBackground'}`}
          >
            <ScrollToTop />

            <Outlet />
          </div>
        </div>
        <BottomBar />
        <GlobalModal
          isOpen={isNewsletterModal}
          onClose={() => {
            // dispatch(setLoginPopup(false));
            dispatch(setNewsletterModal(false));
            // setIsLogInPopClosed(prev => !prev)
          }}
          title={`Announcement: ${PLATFORM_NAME} Platform is Now Live!`}
          childrenClass="p-0"
          size="2xl"
        >
          {/* {isNewsletterModalId === "1" ? <RenderInstructionModal /> : <RenderModalContent />} */}
        </GlobalModal>
      </section>
    </PrelineInit>
  );
};
