import CloudkOverview from './CloudOverview';

const Cloudk = () => {
  return (
    <div>
      <CloudkOverview />
    </div>
  );
};

export default Cloudk;
