import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectSocketIO } from '../../store/socketIO/socketIOSlice';
import { SocketEventOptions } from '../../types/socketIO.types';

interface UseSocketEventOptions<T> {
  eventName: string;
  onData?: (data: T) => void;
  options?: SocketEventOptions;
}

const useSocketEvent = <T>({
  eventName,
  options,
  onData,
}: UseSocketEventOptions<T>) => {
  const { socket } = useSelector(selectSocketIO);
  const receivedOnce = useRef<boolean>(false);

  useEffect(() => {
    if (!socket || !eventName) {
      return;
    }

    if (options?.once && receivedOnce.current) {
      return;
    }

    const handleEvent = (...args: any) => {
      const eventData = args[0] as T;
      if (onData) {
        onData(eventData);
      }
    };

    const method = options?.once ? 'once' : 'on';
    socket[method](eventName, handleEvent);

    return () => {
      socket.off(eventName, handleEvent);
    };
  }, [socket, eventName, options, onData]);
};

export default useSocketEvent;
