import React from 'react';

type Props = {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onBlur?: (e: React.FocusEvent<HTMLElement, Element>) => void;
  children: React.ReactNode;
  cardLayoutStyle?: object;
  isBorder?: boolean;
};

const CardLayout = ({
  className,
  onClick,
  onBlur,
  children,
  cardLayoutStyle,
  isBorder = true,
}: Props) => {
  return (
    <section
      className={`flex flex-col shadow-dashboardDropShadow rounded-xl ${isBorder && `border border-borderBlue`} ${className}`}
      onClick={onClick}
      onBlur={onBlur}
      style={cardLayoutStyle}
    >
      {children}
    </section>
  );
};

export default CardLayout;
