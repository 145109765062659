import React, { useEffect, useState } from 'react';
import CloudKTable from '../../../Common/CloudKTable/CloudKTable';
import CloudKSelect from '../../../Common/CloudKSelect/CloudKSelect';
import { useSelector } from 'react-redux';
import { selectCloudSlice } from '../../../../../../store/cloudk/cloudkSlice';
import { useCloudkActions } from '../../../../../../store/cloudk/cloudkAction';
import { formatAlphaNumericDate, formatNumberWithCommas } from '../../../../../../utils/utils';
import RangeDatePicker from '../../../../../Wallet/RangeDatePicker';
import moment from 'moment';

const columns = [
    { label: 'Sr. #', key: 'id', classNames: 'text-center' },
    { label: 'License ID', key: 'licenseId' },
    { label: 'Transaction Type', key: 'transactionType' },
    { label: 'Amount', key: 'amount' },
    { label: 'Token', key: 'token' },
    { label: 'Date', key: 'date' },
];

const TransactionHistory = () => {
    const { transactionHistoryData, transactionHistoryLoading, allLiciencesIds, allLiciencesStatus } = useSelector(selectCloudSlice);
    const { getCloudKTransactionHistory, getAllLicienceIDsFunc } = useCloudkActions();
    const [licenseIdsOption, setLicenseIdsOption] = useState([])
    const [allLicensesStatusArray, setAllLicensesStatusArray] = useState<any>([])
    const [resetFilter, setResetFilter] = useState(false);
    const [filters, setFilters] = useState<any>({
        limit: 10,
        page: 1,
        licenseId: null,
        licensesStatus: null,
        fromDate: null,
        toDate: null,
    });
    const [resetDisable, setResetDisable] = useState(true);


    useEffect(() => {
        const noData = !transactionHistoryData?.list?.length;
        const hasFilters = filters.licenseId || filters.licensesStatus || filters.fromDate || filters.toDate;

        // Disable if (no data and no filters) OR (data and no filters)
        setResetDisable((noData && !hasFilters) || (!noData && !hasFilters));
    }, [transactionHistoryData, filters]);

    useEffect(() => {
        if (allLiciencesIds?.length > 0) {
            console.log({ allLiciencesIds })
            setLicenseIdsOption(allLiciencesIds.map((item: any) => ({
                value: item.licenseId,
                label: item.identifier
            })))
        }
    }, [allLiciencesIds]);

    useEffect(() => {
        console.log({ allLiciencesStatus })
        if (allLiciencesStatus.length > 0) {
            const statusArray: any = allLiciencesStatus.map((license:any) => ({
                label: license,
                value: license,
            }));

            setAllLicensesStatusArray(statusArray)
        }
    }, [allLiciencesStatus]);

    useEffect(() => {
        getAllLicienceIDsFunc();
        // getAllLicensesStatusFunc();
    }, [])
    useEffect(() => {
        setResetFilter(false);
        getCloudKTransactionHistory({
            page: filters.page,
            limit: filters.limit,
            id: filters?.licenseId?.value,
            type: filters?.licensesStatus?.value,
            fromDate : filters?.fromDate,
            toDate: filters?.toDate,
        });
    }, [filters]);

    const RenderTag = (type : string) => {
        switch (type) {
            case 'purchase':
                return (
                    <span className="py-1 px-2 bg-[#CCEFFF] text-[#147AA7] rounded-md text-xs font-medium">
                        Purchase
                    </span>
                );
            case 'add-stake':
                return (
                    <span className="py-1 px-2 bg-[#0B6D5E1A] text-[#0B6D5E] rounded-md text-xs font-medium">
                        Add Stake
                    </span>
                );
            case 'reward':
                return (
                    <span className="py-1 px-2 bg-[#FFA2A245] text-[#A20000] rounded-md text-xs font-medium">
                        Reward
                    </span>
                );
            default:
                return null; // Or a fallback message/component if needed
        }
    }
    const rows = transactionHistoryData?.list?.map((transaction: any, index: number) => ({
        id: index + 1,
        // licenseId: transaction.userLicenseId,
        licenseId: transaction.userLicenseinfo[0].identifier,
        transactionType: RenderTag(transaction.type),
        amount: <span>{formatNumberWithCommas(transaction.totalPrice)}</span>,
        token: transaction.from,
        date: formatAlphaNumericDate(transaction.createdAt),
    })) || [];
    const handleLicenseChange = (selectedOption: { label: string; value: string }) => {
        setFilters((prev: any) => ({
            ...prev,
            licenseId: selectedOption,
        }));
    };
    const handleTypeChange = (selectedOption: { label: string; value: string }) => {
        setFilters((prev: any) => ({
            ...prev,
            licensesStatus: selectedOption,
        }));
    };

    const handleDateDate = (startDate: any, endDate: any) => {
        const from = moment(startDate).format('YYYY-MM-DD');
        const to = moment(endDate).format('YYYY-MM-DD');

        // setDate({ fromDate: from, toDate: to });
        setFilters((prev: any) => ({
            ...prev,
            fromDate: from,
            toDate: to,
        }));
    };

    return (
        <div>
            <CloudKTable
                rows={rows}
                columns={columns}
                title="Transaction History"
                loading={transactionHistoryLoading}
                filters={[
                    <CloudKSelect
                        options={licenseIdsOption}
                        placeholder="License ID"
                        onChange={handleLicenseChange} // Handle the option change
                        selectedOption={filters.licenseId}
                    />,
                    <CloudKSelect selectedOption={filters.licensesStatus}
                        options={allLicensesStatusArray} placeholder="Transaction Type" onChange={handleTypeChange} />,
                      <RangeDatePicker
                          handleDateDate={handleDateDate}
                          resetFilter={resetFilter}
                          toDateClassName={`!h-9 text-secondaryBlack text-sm`}
                          fromDateClassName={`!h-9 text-secondaryBlack text-sm`}
                    // setDateChanged={true}
                    // fromDateDisable={resetDisable}
                // fromDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''}`}
                // toDateDisable={resetDisable}
                // toDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''}`}
                  />,
                    <button
                        className={`${resetDisable ? 'cursor-not-allowed text-primaryGrey' : 'text-primary'} text-xs font-medium h-10`}
                        type="button"
                        disabled={resetDisable}
                        onClick={() => {
                            setResetFilter(true);
                            setFilters({
                                limit: 10,
                                page: 1,
                                licenseId: null,
                                licensesStatus: null,
                                fromDate: null,
                                toDate: null,
                            });

                        }}
                    >
                        Reset
                    </button>
                ]}
                // navigationContainerStyle="!justify-center"
                // totalPagesProp={transactionHistoryData?.totalPages}
                // currentPageProp={transactionHistoryData?.currentPage}
            />
        </div>
    );
};

export default TransactionHistory;
