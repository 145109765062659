/* eslint-disable react-hooks/exhaustive-deps */
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { NewsLetterIcon } from '../../images/svgs';
import { useNewsletterActions } from '../../store/newsLetter/newLetterActions';
import { selectNewsletter } from '../../store/newsLetter/newsLetterSlice';
import { INewsletterList } from '../../types/newsletter.types';
import { formatRelativeTime } from '../../utils/allConstants';
import GlobalModal from '../Global/GlobalModal';

const NewsLetterTabContent = () => {
  const { readNewsletter } = useNewsletterActions();
  const { newsletterList, unReadNewsletterCount } =
    useSelector(selectNewsletter);
  const [selectedNewsLetter, setSelectedNewsLetter] =
    useState<INewsletterList | null>(null);

  const onReadNewsletterHandler = async (id: string) => {
    await readNewsletter(id);
  };

  const filterNewsletter = useMemo(() => {
    return newsletterList;
  }, [newsletterList, unReadNewsletterCount]);

  return (
    <>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={{ duration: 0.3 }}
          className="flex flex-col"
          style={{ height: '100%', width: '100%' }}
        >
          <div className="w-full h-full">
            {filterNewsletter.length > 0 ? (
              filterNewsletter.map((itm: any, index: number) => {
                const read: boolean = itm.read;
                return (
                  <div className="" key={index}>
                    <div
                      className={`flex justify-between w-full gap-2 border-b border-borderLightGray p-4 cursor-pointer ${!read && 'bg-primary/10'}`}
                      onClick={() => {
                        setSelectedNewsLetter(itm);
                        if (!read) onReadNewsletterHandler(itm._id);
                      }}
                    >
                      <div className="w-10 h-10">
                        <div className="flex justify-center items-center bg-white rounded-full w-12 h-12 border border-gray-400">
                          <NewsLetterIcon />
                        </div>
                      </div>
                      <div className="flex flex-col justify-center gap-2 w-full">
                        <div className="w-full px-2">
                          {itm.newsDetails?.title}
                        </div>
                      </div>
                      <span className="flex flex-col gap-2 items-center text-xs text-primaryGrey  min-w-8">
                        {formatRelativeTime(itm.createdAt)}
                        {!read && (
                          <div className="w-2 h-2 bg-primaryRed rounded-full" />
                        )}
                      </span>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="w-full h-20 flex items-center justify-center">
                No Notifications Found!
              </div>
            )}
          </div>
        </motion.div>
      </AnimatePresence>
      <GlobalModal
        isOpen={selectedNewsLetter != null}
        onClose={() => setSelectedNewsLetter(null)}
        size="2xl"
        title={selectedNewsLetter?.newsDetails?.title}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: selectedNewsLetter?.newsDetails?.body ?? '',
          }}
        ></div>
      </GlobalModal>
    </>
  );
};

export default NewsLetterTabContent;
