//REDUX-TOOLKIT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TPlatformSettings = {
  _id: string;
  name: string;
  value: string;
  description: string;
  type: string;
  options: any[];
  category: string;
  createdAt: string;
  updatedAt: string;
};

interface State {
  links: any;
  pagesEnable: any;
  platformSettings?: TPlatformSettings[];

  getPlatformSettingsLoading: boolean;
}

const initialState: State = {
  links: [],
  pagesEnable: null,

  getPlatformSettingsLoading: false,
};

const slice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setLink: (state, action: PayloadAction<any>) => {
      return { ...state, links: action.payload };
    },
    setPagesEnable: (state, action: PayloadAction<any>) => {
      return { ...state, pagesEnable: action.payload };
    },
    setPlatformSettings: (state, action: PayloadAction<any>) => {
      return { ...state, platformSettings: action.payload };
    },

    setSettingSlice: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setLink, setPagesEnable, setPlatformSettings, setSettingSlice } =
  slice.actions;
export const selectSetting = (state: { setting: State }) => state.setting;
export default slice.reducer;
