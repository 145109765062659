import { ResponsiveLine } from '@nivo/line';
import { TChartDataType } from '../../types/Charts/lineChart.types';

type Props = {
  containerClassName?: string;
  isCloudKChart?: boolean;
  data: TChartDataType;
  margin?: object;
  enablePoints?: boolean;
  defs?:
  | {
    [key: string]: any;
    id: string;
  }[]
  | undefined;
  fill?:
  | {
    id: string;
    match: Record<string, unknown> | '*' | any;
  }[]
  | undefined;
  colors?: string[];
  axisTop?: null | object;
  axisRight?: null | object;
  axisBottom?: null | object;
  axisLeft?: null | object;
  enableGridY?: boolean;
  enableGridX?: boolean;
  isInteractive?: boolean;
  tooltipText?: string;
  legends?: any;
  tootlipColor?: string;
  showSub?: boolean;
  enableArea?: boolean;
  theme?: any;
  tootlipText?: string;
  containerClass?: string;
  gridXValues?: any;
  gridYValues?: any;
  curve?: any;
  areaOpacity?: number;
  xScale?: any;
  yScale?: any;
  pointSize?: any;
  pointColor?: any,
  pointBorderWidth?: any,
  pointLabel?: any,
  pointLabelYOffset?: any,
  pointBorderColor?: any,
};

const CustomSliceTooltip = ({ slice }: any) => (
  <div
    style={{
      background: 'black',
      padding: '9px 12px',
      border: '1px solid #ccc',
      width: '300px',
    }}
  >

    <strong>{slice.points[0].data.xFormatted}</strong>
    {slice.points.map((point: any) => (
      <div
        key={point.id}
        style={{
          color: point.serieColor,
          padding: '3px 0',
        }}
      >
        <strong>{point.serieId}</strong> [{point.data.yFormatted}]
      </div>
    ))}
  </div>
);

const LineChart = ({
  data,
  margin = { top: 20, bottom: 50, left: 45, right: 20 },
  enablePoints = false,
  isCloudKChart = false,
  defs,
  fill,
  containerClass = '',
  colors = ['#fff', '#EB6535'],
  axisTop,
  axisBottom = {
    orient: 'bottom',
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'x-axis legend',
    legendOffset: 36,
    legendPosition: 'middle',
  },
  axisLeft = {
    orient: 'left',
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'y-axis legend',
    legendOffset: -40,
    legendPosition: 'middle',
    format: (value: any) => `${value} units`, // Ensure this works as expected
  },
  axisRight,
  enableGridX = true,
  enableGridY = true,
  xScale,
  isInteractive = true,
  legends = [
    {
      anchor: 'bottom-right',
      direction: 'column',
      justify: false,
      translateX: 100,
      translateY: 0,
      itemsSpacing: 0,
      itemDirection: 'left-to-right',
      itemWidth: 80,
      itemHeight: 20,
      itemOpacity: 0.75,
      symbolSize: 12,
      symbolShape: 'circle',
      symbolBorderColor: 'rgba(0, 0, 0, .5)',
      effects: [
        {
          on: 'hover',
          style: {
            itemBackground: 'rgba(0, 0, 0, .03)',
            itemOpacity: 1,
          },
        },
      ],
    },
  ],
  tootlipColor,
  showSub,
  enableArea,
  theme,
  tootlipText = '',
  gridXValues,
  gridYValues,
  curve = 'linear',
  areaOpacity = 0.35,
  yScale,
  pointSize,
  pointColor,
  pointBorderWidth, pointLabel,
  pointLabelYOffset,
  pointBorderColor,
  containerClassName

}: Props) => {
  const yValues = data.reduce<number[]>((acc, series) => {
    series.data.forEach((point) => {
      if (point.y !== null) {
        acc.push(point.y);
      }
    });
    return acc;
  }, []);
  const maxY = Math.max(...yValues);
  const minY = Math.min(...yValues);

  const yScaleMin = minY ? Math.floor(minY) : 0;

  const yScaleMax = maxY ? Math.ceil(maxY) : 1;

  return (
    <div
      className={`${containerClassName ? '' : 'xxs:px-0 md:px-3'} flex flex-col md:flex-row items-center justify-between gap-2 md:gap-4 bg-bgSecondary rounded-lg w-full h-full  ${containerClass}`}
    >
      <div className="w-full h-full md:w-full md:h-full text-black">
        <ResponsiveLine
          data={data}
          areaOpacity={areaOpacity}
          enableGridX={enableGridX}
          enableGridY={enableGridY}
          pointLabelYOffset={pointLabelYOffset}
          margin={margin}
          layers={[
            'grid',
            'lines',
            'areas',
            'crosshair',
            'markers',
            'mesh',
            'points',
            'slices',
            'legends',
            'axes',
          ]}
          xScale={xScale || { type: 'point' }}
          yScale={yScale || {
            type: 'linear',
            min: yScaleMin,
            max: yScaleMax,
            // nice: true,
            // min: 0,
          }}
          isInteractive={isInteractive}
          pointSize={pointSize || 0}
          pointColor={pointColor || ''}
          pointBorderWidth={pointBorderWidth || 0}
          pointBorderColor={pointBorderColor || 10}
          enablePointLabel={false}
          pointLabel={pointLabel || ''}
          areaBlendMode={'normal'}
          areaBaselineValue={yScaleMin}
          lineWidth={2}
          legends={legends}
          debugMesh={false}
          enableSlices={false}
          debugSlices={false}
          sliceTooltip={CustomSliceTooltip}
          enablePoints={enablePoints}
          enableCrosshair={false}
          crosshairType="x"
          role="tooltip"
          theme={
            theme || {
              text: {
                fontSize: '12px',
                fill: '#AAAAAA',
              },
              grid: {
                line: {
                  stroke: '#FFFFFF40',
                  strokeWidth: 1,
                },
              },
              axis: {
                ticks: {
                  line: {
                    stroke: '#FFFFFF40',
                  },
                  text: {
                    fontSize: '8px',
                    fill: '#7D7D7D',
                  },
                },
              },
            }
          }
          colors={colors}
          enableArea={enableArea}
          gridXValues={gridXValues}
          gridYValues={gridYValues}
          defs={defs}
          fill={fill}
          tooltip={({ point }: any) => {
            return (
              <>{
                isCloudKChart ? (
                  <div className='bg-white flex flex-col px-2 py-3 gap-1' style={{
                    border: `1px solid ${point.borderColor}`
                  }}>
                    <span className='text-[#101010] font-bold '>{point.data.y} {point?.serieId}</span>
                    <span className='text-[#616161]'>{point.data.x}</span>
                  </div>
                ) : (<div className="flex flex-col items-center justify-center gap-1 w-96">
                  <div className="flex flex-col gap-1 absolute top-2">
                    <div className="flex gap-1">
                      <p className="text-xs font-normal text-secondaryBlack">
                        {/* {point?.data?.y?.toString()?.length > 5
                        ? `${point?.data?.y?.toString()?.slice(0, 4)}...`
                        : point?.data?.y.toLocaleString()} */}
                        {point?.data?.y.toLocaleString()}
                      </p>
                      {/* <p className="text-xs font-normal text-grayText">
                      {tootlipText || point?.serieId}
                    </p> */}
                    </div>

                    {showSub && (
                      <p className="text-xs text-[#939393]">{point?.data?.x}</p>
                    )}
                  </div>
                  <svg
                    className="w-32"
                    viewBox="0 0 70 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M39 26.5H38.7929L38.6464 26.6464L35 30.2929L31.3536 26.6464L31.2071 26.5H31H4C2.067 26.5 0.5 24.933 0.5 23V4C0.5 2.067 2.067 0.5 4 0.5H66C67.933 0.5 69.5 2.067 69.5 4V23C69.5 24.933 67.933 26.5 66 26.5H39Z"
                      fill="#ffff"
                      stroke={tootlipColor || '#7583FF'}
                    />
                  </svg>
                  <div
                    className={`w-2 h-2 rounded-full border bg-${tootlipColor}`}
                  />
                </div>)

              }</>

            );
          }}
          axisTop={axisTop}
          axisRight={axisRight}
          axisLeft={axisLeft}
          axisBottom={axisBottom}
          enableTouchCrosshair={true}
          useMesh={true}
          curve={curve}
        />
      </div>
    </div>
  );
};

export default LineChart;
