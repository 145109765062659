const PreLoader = () => {
  return (
    <div className="flex items-center justify-center w-full h-full ">
      {/* <Spinner className="w-16 h-16 animate-spin text-primary" /> */}
      <img src="\img\Loader\Hominifi_Loading.gif" alt="" width={170} />
    </div>
  );
};

export default PreLoader;
