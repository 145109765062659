/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useUserCheck from '../../hooks/useUserCheck';
import PrelineSetup from '../PrelineSetup';
import AuthHeader from './AuthHeader';
import AuthSliderWrapper from './AuthSliderWrapper';

export default function VerificationLayout(): JSX.Element {
  const { user, checkLoginValidation, fetchUser } = useUserCheck();
  useEffect(() => {
    checkLoginValidation();
  }, [user]);
  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <PrelineSetup>
      <div className="flex flex-col gap-4 max-w-[1200px] lg:px-20 md:px-10 sm:px-8 px-4 items-center m-auto">
        <AuthHeader />
        <div className="flex justify-between items-center w-full  md:gap-32 lg:gap-48 py-14 p-0">
          <Outlet />
          <AuthSliderWrapper />
        </div>
      </div>
    </PrelineSetup>
  );
}
