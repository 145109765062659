import { useEffect, useState } from 'react';
import { useWalletDataAction } from '../store/walletData/walletDataAction';
import { useSelector } from 'react-redux';
import { selectWalletData } from '../store/walletData/walletDataSlice';
import { selectUserSlice } from '../store/user/userSlice';
import { useUserActions } from '../store/user/userActions';

const useShowSpecialSwap = () => {
  const [showSpecialSwap, setShowSpecialSwap] = useState<boolean | null>(null);
  const { getUser } = useUserActions();
  const { getWalletDataSpecialSwapSettings } = useWalletDataAction();
  const { specialSwapSettings } = useSelector(selectWalletData);
  const { user } = useSelector(selectUserSlice);
  useEffect(() => {
    getUser();
    getWalletDataSpecialSwapSettings(user?.documentCountry);
  }, []);
  useEffect(() => {
    if (user && specialSwapSettings) {
      setShowSpecialSwap(specialSwapSettings?.length > 0 && user.kycApproved);
      // setShowSpecialSwap(specialSwapSettings?.length > 0);
    }
  }, [user, specialSwapSettings]);

  return showSpecialSwap;
};

export default useShowSpecialSwap;
