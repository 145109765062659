import React, { useState } from 'react'
import GlobalTextInput from '../../../../../../components/Global/GlobalTextInput';
import { direct_withdraw_percentage } from '../../../../../../utils/data/data';
import { useSelector } from 'react-redux';
import { selectCloudSlice } from '../../../../../../store/cloudk/cloudkSlice';
import GlobalSelectMui from '../../../../../../components/Global/GlobalSelectMui';
import GlobalButton from '../../../../../../components/Global/GlobalButton';
import { useCloudkActions } from '../../../../../../store/cloudk/cloudkAction';


  const lockupOptions = [{
    name: '12 Months',
  }, {
      name: '24 Months',
    }, {
      name: '36 Months',
    }]

const AddMintingToken = () => {
  const [inputAmount, setInputAmount] = useState<number | null>(null)
  const [directAmountPercentage, setDirectAmountPercentage] = useState<number | null>(null)
  const [selectedOption, setSelectedOption] = useState<any>('')
  
  const { availableCloudKBalance, createMintingTokenLoading } = useSelector(selectCloudSlice)
  const { addMintingTokenFunc } = useCloudkActions()

  return (
    <div className='flex flex-col gap-2 border-t border-t-[#D5DBE0] p-6'>
      <span className='font-medium text-[#292929]'>Selected License</span>
      <span className='font-medium text-[#121212] text-2xl'>LayerK XSTK-220 </span>

<div className='flex flex-col gap-4 mt-5'>
      <div className="flex flex-col gap-2 w-full">
        <div className="text-defaultBlack text-sm font-medium">
          Amount <span className="text-redDanger">*</span>
        </div>
        <div className="w-full relative">
          <GlobalTextInput
            name='amount'
            placeholder='0.00'
            type="number"
            className={'!rounded-[50px] bg-gray-50'}
            animationClasses='!rounded-[50px]'
            value={inputAmount || ''}
            style={{ cursor: 'default', appearance: 'none' }}
            errorMessage={
              (inputAmount ?? 0) > availableCloudKBalance ?
                `You can’t stake more than $ ${Number(availableCloudKBalance)?.toFixed(2)}`: ''
            }
            errorStyle={'!text-left'}
            onChange={(e) => {
              const number = Number(e.target.value);
              setDirectAmountPercentage(0)
              setInputAmount(number)
            }}
          />
          {(
            <span className="flex  h-full absolute right-3 top-2 py-2 text-[#AAAAAA] text-xs bg-bgSecondary">
              <span>Balance:</span> <span>{`${Number(availableCloudKBalance)?.toFixed(2)} USDT`}</span>
            </span>
          )}
        </div>
        <div className="flex items-center gap-2 justify-end">

          {availableCloudKBalance 
            ? direct_withdraw_percentage?.map(
              (percentage: number, index: number) => (
                <button
                  key={index}
                  onClick={() => {
                    setDirectAmountPercentage(percentage)
                    setInputAmount((availableCloudKBalance * percentage) / 100)
                  }}
                  className={` rounded-full px-3 py-1 text-xs text-textSecondary border
                      ${directAmountPercentage ===
                      direct_withdraw_percentage[index]
                      ? 'bg-primary text-white border !border-buttonColor'
                      : 'border-secondary '
                    }
                        `}
                >
                  {percentage}P.
                </button>
              )
            )
            : ''}
        </div>
      </div>


      <div className="flex-1 flex items-start justify-between flex-col gap-5">
        <div className="flex flex-col gap-2 w-full">
          <div className="text-defaultBlack text-sm font-medium">
            Lockup Period 
            {/* <span className="text-redDanger">*</span> */}
          </div>
          { (
            <>
              <GlobalSelectMui
                required={true}
                className={`!rounded-[50px]`}
                options={
                  lockupOptions
                    ? lockupOptions?.map((item: any) => ({
                      ...item,
                      value: item.name,
                    }))
                    : []
                }
                  selectedOption={selectedOption}
                setSelectedOption={(option :any) => {
                  setSelectedOption(option);
                }}
                placeHolder={'Select a lockup period'}
                firstAutoSelected={false}
              />
            </>
          )}
        </div>
   
   
      </div>

        <GlobalButton
          text="Update Changes"
          type="button"
          loading={createMintingTokenLoading}
          disabled={!createMintingTokenLoading && inputAmount && selectedOption ? false : true}
          classNames={`!w-full rounded-[10px] !text-base !py-[10px] px-5 min-w-max place-item-end 
           ${!createMintingTokenLoading && inputAmount && selectedOption ? '!bg-updateBtnGredient' : 'bg-[#C0D5DF]'} font-medium !text-[#FFFFFF]`}
          onClick={()=>{
            const data={
              amount: inputAmount,
              lockupPeriod: selectedOption && selectedOption?.value
            }
            addMintingTokenFunc(data) 
          }}
        />
      </div>
    </div>
  )
}

export default AddMintingToken
