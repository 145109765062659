//REDUX-TOOLKIT
import { useDispatch } from 'react-redux';
import {
  setAllNewsletterList,
  setIsLoading,
  setMarkAllAsRead,
  setNewsletterList,
  setUserActivity,
  updateNewsletterStatus,
} from './newsLetterSlice';
//UTILS
import {
  CREATE_NEWS_LETTER,
  GET_NEWS_LETTER,
  GET_USER_ACTIVITY,
  READ_NEWS_LETTER,
} from '../../utils/network/ApiEndpoints';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';

export const useNewsletterActions = () => {
  const dispatch = useDispatch();

  const getUserActivity = async (page = 1, force = false) => {
    const url = GET_USER_ACTIVITY + '?page=' + page;

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: url,
      })
      .then((response: any) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setUserActivity({
            activityData: data,
            page,
            force,
          })
        );
        return data;
      })
      .catch((error: any) => error);
  };

  const deleteNewsletter = async (notification_id: string) => {
    //   const url = DELETE_NOTIFICATIONS + notification_id;
    //   return await ApiRequestHomnifi()
    //     .request({
    //       method: 'POST',
    //       url: url,
    //     })
    //     .then((response: any) => {
    //       dispatch(removeNotification(notification_id));
    //       return response;
    //     })
    //     .catch((error: any) => error);
  };

  // const readAllNewsletter = async () => {
  //   return await ApiRequestHomnifi()
  //     .request({
  //       method: 'POST',
  //       url: READ_ALL_NOTIFICATIONS,
  //     })
  //     .then((response: any) => {
  //       return response;
  //     })
  //     .catch((error: any) => error);
  // };

  const getBadge = async () => {
    // return await ApiRequestHomnifi()
    //   .request({
    //     method: 'GET',
    //     url: NOTIFICATIONS_BADGE,
    //   })
    //   .then((response: any) => {
    //     dispatch(setBadge(response.data.data.badge));
    //     return response;
    //   })
    //   .catch((error: any) => error);
  };
  const getNewsletter = async (page: number = 1, limit: number = 10) => {
    dispatch(setIsLoading(true));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${GET_NEWS_LETTER}?page=${page}&limit=${limit}`,
      })
      .then((response: any) => {
        const {
          data: { data },
        } = response;

        dispatch(setNewsletterList(data));
        return response;
      })
      .catch((error: any) => {
        return error;
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  const getAllNewsletter = async () => {
    dispatch(setIsLoading(false));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${GET_NEWS_LETTER}`,
        params: {
          page: 1,
          limit: 100,
        },
      })
      .then((response: any) => {
        const {
          data: {
            data: { list },
          },
        } = response;
        dispatch(setAllNewsletterList(list));
        return response;
      })
      .catch((error: any) => {
        return error;
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

  const readNewsletter = async (id: string, page: number = 1) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: `${READ_NEWS_LETTER}/${id}`,
      })
      .then((response: any) => {
        const {
          data: { status },
        } = response;
        if (status === 201 || status === true) {
          dispatch(updateNewsletterStatus(id));
        }
        return response;
      })
      .catch((error: any) => error);
  };

  const readAllNewsletter = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: READ_NEWS_LETTER,
      })
      .then((response: any) => {
        dispatch(setMarkAllAsRead(0));
        // getHomnifiNotification();
        return response;
      })
      .catch((error: any) => error);
  };

  const createNewsletter = async (data: any) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: CREATE_NEWS_LETTER,
        data,
      })
      .then((response: any) => {
        getNewsletter();
        return response;
      })
      .catch((error: any) => error);
  };

  return {
    getNewsletter,
    getAllNewsletter,
    createNewsletter,
    deleteNewsletter,
    readNewsletter,
    readAllNewsletter,
    getBadge,
    getUserActivity,
  };
};
