// src/components/PrelineInit.tsx
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import 'preline/preline';
import { IStaticMethods } from 'preline/preline';

declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

const PrelineInit: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  return <>{children}</>;
};

export default PrelineInit;
