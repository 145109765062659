import React, { ReactNode } from 'react';
import PrelineInit from './PrelineInit';
import PreLoader from './Global/PreLoader';

type Props = {
  children: ReactNode;
};

const PrelineSetup = ({ children }: Props) => {
  return (
    <React.Suspense fallback={<PreLoader />}>
      <PrelineInit>{children}</PrelineInit>
    </React.Suspense>
  );
};

export default PrelineSetup;
