import React from 'react'
import CardLayout from '../../../../../components/Cards/CardLayout'
import CustomSwitch from '../../../../../components/Global/GlobalSwitch'
import { useSelector } from 'react-redux'
import { selectCloudSlice } from '../../../../../store/cloudk/cloudkSlice'
import { tokenIcon1, tokenIcon2 } from '../../../../../images/cloudk'
import GlobalButton from '../../../../../components/Global/GlobalButton'
import { ClaimIcon } from '../../../../../images/svgs'
import { useCloudkActions } from '../../../../../store/cloudk/cloudkAction'
import { formatNumberWithCommas } from '../../../../../utils/utils'
import { ReactComponent as Spinner } from '../../../../../SVGIcons/spinner.svg';

const rewardGeneratedData = [
    {
        id: 1,
        icon: tokenIcon1,
        name: 'LYK-W',
        amount: 8023586,
        amountColor: '#7583FF',

    },
    {
        id: 2,
        icon: tokenIcon2,
        name: 'LYK-R',
        amount: 8023586,
        amountColor: '#7FC9FF',

    }
]

const RewardGenerated = () => {
    const { isGenerationSwitch, claimableRewardLoading, claimableRewardAutoLinkLoading } = useSelector(selectCloudSlice)
    const { cloudKClaimableReward, cloudKClaimableRewardAutoLinkFunc }  = useCloudkActions()

    return (
        <CardLayout className="md:col-span-5 col-span-12 p-5 flex flex-col gap-9 !shadow-boxShadow">
            <div className='flex flex-col gap-4'>
                <div className='flex justify-between items-center'>
                    <h1 className="text-secondaryBlack font-medium sm:text-lg text-sm">
                        Claimable Rewards 
                    </h1>
            
                </div>
                <span className="text-cardSecondaryText sm:text-sm text-xs font-normal flex-grow text-justify ">
                    Lorem ipsum dolor sit amet consectetur. Cras egestas et placerat aliquet id tristique. Ac cursus varius vitae dictumst lacus eu vel sed.
                </span>
 
            </div>

            <div className='flex flex-col gap-10'>
                <div className='  flex xl:flex-row xl:gap-12 lg:flex-col flex-col sm:flex-row sm:gap-12 lg:gap-4 gap-4'>
                    {rewardGeneratedData.map((reward) => {
                        return (
                            <div className='flex gap-3' key={reward.id}>
                                <div className='w-[34px] h-[34px]'>
                                    <img src={reward.icon} alt="icon" className='w-full h-full' />
                                </div>
                                <div className='flex flex-col gap-1'>
                                    <span className='text-xl font-medium text-black'>{reward.name}</span>
                                    <span className={`text-2xl font-medium`}
                                        style={{ color: reward.amountColor }}
                                    > {formatNumberWithCommas(reward.amount)}</span>
                                </div>
                            </div>
                        )
                    })}


                </div>

                <div className='flex justify-between gap-2 sm:items-center xl:items-center sm:flex-row md:flex-col xl:flex-row flex-col w-full'>
                    <div className='flex gap-3 w-full items-center'>
                        <span className='text-[#121212] text-base font-medium'>Auto Link</span>
                        {claimableRewardAutoLinkLoading ? <div className="flex justify-center items-center  w-6 h-6">
                            <div className="justify-center items-center self-center">
                                <div className="flex flex-col gap-5">
                                    <Spinner className="animate-spin h-6" />
                                </div>
                            </div>
                        </div> : <CustomSwitch
                            checked={isGenerationSwitch}
                            onChange={() => {
                                cloudKClaimableRewardAutoLinkFunc()
                            }}
                            className="reset-pass-switch"
                            parentClassName="reset-pass-switch-checked"
                        /> }  

                        {/* <div className="flex items-center">
                            <label className="relative cursor-pointer">
                                <input type="checkbox" className="sr-only peer" />
                                <div
                                    className="w-[77px] border border-[#CFCFCF] h-[34px]  items-center bg-white rounded-full text-[9px]                 
                                    font-extrabold peer-checked:bg-[#9EEB5E] 
                  after:absolute after:left-1 after:top-1 after:bg-[#CFCFCF] peer-checked:after:bg-[#FFFFFF] 
                  after:rounded-full after:h-[27px] after:w-[27px] 
                  after:transition-transform peer-checked:after:translate-x-[43px]  justify-between px-2"
                                >                            
                                </div>
                            </label>
                        </div> */}

                    </div>
                    <div className='flex w-full justify-end'>
                    <GlobalButton
                        text="Claim Rewards"
                        type="button"
                        disabled={false}
                        loading={claimableRewardLoading}
                        StartIcon={
                            <ClaimIcon className="relative z-10 w-4 h-4 col-span-1" fill={claimableRewardLoading ? 'white' : 'none'} stroke={claimableRewardLoading ? 'white' : "#7AB6D1"} />
                        }
                            classNames={`!w-[180px] rounded-lg font-normal !text-sm !py-[10px] px-5 min-w-max place-item-end  !bg-[#E0F3F9] font-semibold !text-[#7AB6D1]
                               ${claimableRewardLoading ? '!bg-[#C0D5DF] cursor-not-allowed !text-white' : ''} `}
                        
                            onClick={cloudKClaimableReward}
                    />
                    </div>
                </div>

            </div>


        </CardLayout>
    )
}

export default RewardGenerated
