import { useEffect } from 'react';
import CloudKTransactionHistory from '../TransactionHistory';
import AllHardwares from './components/AllHardwares';
import AllLicenses from './components/AllLicenses';
import Heading from './components/Heading';
import RewardAnalytics from './components/RewardAnalytics';
import RewardGenerated from './components/RewardGenerated';
import TopMachines from './components/TopMachines';
import TotalLicenses from './components/TotalLicenses';
import TotalLinkedMlyk from './components/TotalLinkedMlyk';
import TotalRewardGenerated from './components/TotalRewardGenerated';
import TransactionHistory from './components/TransactionHistory/TransactionHistory';
import { useCloudkActions } from '../../../../store/cloudk/cloudkAction';

const CloudkOverview = () => {
  const { getActiveLicensesCountFunc } = useCloudkActions()
  useEffect(()=>{
    getActiveLicensesCountFunc()
  },[])
  return (
    <div className="flex flex-col gap-6">
      <Heading />
      <article className="space-y-5 lg:space-y-0 lg:grid grid-cols-12 gap-5">
        <RewardAnalytics />
        <RewardGenerated />
      </article>

      <article className="space-y-5 lg:space-y-0 lg:grid grid-cols-12 gap-5">
        <TotalLicenses />
        <TotalRewardGenerated />
        <TotalLinkedMlyk />
        <TopMachines />
        {/* <TotalLinked /> */}
        {/* <TotalStaked /> */}
      </article>
      <article className="space-y-5 lg:space-y-0 lg:grid grid-cols-12 gap-5">
        <AllLicenses />
      </article>

      <article className="space-y-5 lg:space-y-0 lg:grid grid-cols-12 gap-5">
        <AllHardwares />
      </article>
      <TransactionHistory />
      {/* <CloudKTransactionHistory /> */}
    </div>
  );
};

export default CloudkOverview;
