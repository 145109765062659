import React, { memo } from 'react';
import { Input } from '../ui/InputAnimations';
type TGlobalInput = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  errorMessage?: string;
  labelStyle?: string;
  children?: React.ReactNode;
  animationClasses?: string;
  errorStyle?: string;
  min?: any;
  step?:any;
};
const GlobalTextInput = ({
  label,
  errorMessage,
  children,
  labelStyle,
  animationClasses,
  min,
  step,
  errorStyle,
  ...props
}: TGlobalInput) => {
  return (
    <div className={`flex flex-col gap-2`}>
      {label && (
        <div
          className={`text-sm ${props.disabled ? 'font-normal' : 'font-medium'} ${!!labelStyle && labelStyle}`}


          
        >
          {label}
          {props.required && <span className="text-redDanger">*</span>}
        </div>
      )}
      <div className="relative flex items-center justify-center">
        <Input
          {...props}
          autoComplete={props.type === 'password' ? 'new-password' : 'off'}
          animationClasses={animationClasses}
          className={`placeholder:disabled:font-normal focus-visible:outline-none focus-visible:ring-[1px]  ${errorMessage ? 'ring-redDanger' : 'ring-primary'}  focus-visible:border-none  disabled:bg-transparent  disabled:font-semibold outline-none border py-2 px-4 text-xs !rounded-md w-full ${
            errorMessage ? '!border-redDanger ' : '!border-blueGrey'
          } ${typeof props.className === 'string' ? props.className : ''}`}
          isError={!!errorMessage}
          min={min}
          step={step}
        />
        {children}
      </div>
      {errorMessage && (
        <span className={`text-xs text-redDanger text-right ${errorStyle}`}>
          {errorMessage}
        </span>
      )}
    </div>
  );
};
export default memo(GlobalTextInput);
