import React from 'react';
import {
  KYCIcon,
  NotificationIconGray,
  ProfileIcon,
  RecentIcon,
  SecurityIcon,
} from '../../images/svgs';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserSlice, setUserState } from '../../store/user/userSlice';
import useUserCheck from '../../hooks/useUserCheck';
import { motion } from 'framer-motion';

// export type TabId = 'profile' | 'kyc' | 'security' | 'notification' | 'recentDevices';

export const TABS_KEYS = {
  PROFILE: 'PROFILE',
  KYC: 'KYC',
  SECURITY: 'SECURITY',
  NOTIFICATION: 'NOTIFICATION',
  RECENT_DEVICES: 'RECENT_DEVICES',
};

const Tabs: React.FC<any> = () => {
  const { user } = useUserCheck();

  const _tabs = [
    {
      id: TABS_KEYS.PROFILE,
      label: 'Profile',
      icon: <ProfileIcon className={`h-4`} />,
    },
    {
      id: TABS_KEYS.KYC,
      label: user?.accountType === 'PERSONAL' ? 'KYC' : 'KYB',
      icon: <KYCIcon className="h-4" />,
    },
    {
      id: TABS_KEYS.SECURITY,
      label: 'Security',
      icon: <SecurityIcon className="h-4" />,
    },
    {
      id: TABS_KEYS.NOTIFICATION,
      label: 'Notification Settings',
      icon: <NotificationIconGray className="h-4 " />,
    },
    {
      id: TABS_KEYS.RECENT_DEVICES,
      label: 'Recent Devices',
      icon: <RecentIcon className="h-4" />,
    },
  ];

  const tabVariant = {
    active: {
      transition: {
        type: 'tween',
        duration: 0.4,
      },
    },
    inactive: {
      width: '50px',
      transition: {
        type: 'tween',
        duration: 0.4,
      },
    },
  };

  const tabTextVariant = {
    active: {
      opacity: 1,
      x: 0,
      display: 'block',
      transition: {
        type: 'tween',
        duration: 0.3,
        delay: 0.3,
      },
    },
    inactive: {
      opacity: 0,
      x: -30,
      transition: {
        type: 'tween',
        duration: 0.1,
        delay: 0.1,
      },
    },
  };

  const { profileActiveTab } = useSelector(selectUserSlice);
  const dispatch = useDispatch();

  return (
    <div className=" relative w-full cursor-pointer">
      {_tabs.map((tab) => (
        <motion.button
          key={tab.id}
          type="button"
          className={`${
            profileActiveTab === tab.id
              ? ' border-primary font-medium text-primary bg-primary bg-primary/15'
              : 'border-borderLightGray text-black'
          } mr-4 bg-white py-2 px-3 rounded-md inline-flex items-center gap-2 border mt-4 sm:mt-0 text-sm whitespace-nowrap `}
          variants={tabVariant}
          animate={profileActiveTab === tab.id ? 'active' : 'inactive'}
          onClick={() => dispatch(setUserState({ profileActiveTab: tab.id }))}
        >
          <div className="transition-all duration-500 ease-in-out">
            {tab.icon}
          </div>
          <motion.span
            className="font-bold transition-colors duration-500 ease-in-out"
            variants={tabTextVariant}
          >
            {tab.label}
          </motion.span>
        </motion.button>
      ))}
    </div>
  );
};

export default Tabs;
