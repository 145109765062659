import { createSlice } from '@reduxjs/toolkit';

interface State {
  scrollLeft: number;
}

const initialState: State = {
  scrollLeft: 0,
};
export interface ScrollerState {
  scroller: {
    scrollLeft: number;
  };
}

const scrollerSlice = createSlice({
  name: 'scroll',
  initialState,
  reducers: {
    setScrollLeft: (state, action) => {
      state.scrollLeft = action.payload;
    },
  },
});

export const { setScrollLeft } = scrollerSlice.actions;
export default scrollerSlice.reducer;
