import React from 'react';
import { usdtRound } from '../../images/others';

export const XPLIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M19.701 12.4192C18.3652 17.7763 12.9384 21.0363 7.57994 19.7007C2.22402 18.3651 -1.03666 12.9389 0.299432 7.58202C1.63458 2.22424 7.06113 -1.03637 12.418 0.299247C17.7761 1.63487 21.0365 7.06171 19.7007 12.4192H19.701Z"
      fill="#F26BD4"
    />
    <path
      d="M12.6658 14.3996L10.2894 11.4369L8.13338 14.3996H5.64682L9.10912 9.96179L5.59961 5.59961H8.13338L10.5098 8.54975L12.6501 5.59961H15.1367L11.6901 10.0248L15.1996 14.3996H12.6658Z"
      fill="white"
    />
  </svg>
);

export const PUSDIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M19.701 12.4192C18.3652 17.7763 12.9384 21.0363 7.57994 19.7007C2.22402 18.3651 -1.03666 12.9389 0.299432 7.58202C1.63458 2.22424 7.06113 -1.03637 12.418 0.299247C17.7761 1.63487 21.0365 7.06171 19.7007 12.4192H19.701Z"
      fill="#6289EE"
    />
    <path
      d="M12.6658 14.3996L10.2894 11.4369L8.13338 14.3996H5.64682L9.10912 9.96179L5.59961 5.59961H8.13338L10.5098 8.54975L12.6501 5.59961H15.1367L11.6901 10.0248L15.1996 14.3996H12.6658Z"
      fill="white"
    />
  </svg>
);

export const AUSDIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M19.701 12.4192C18.3652 17.7763 12.9384 21.0363 7.57994 19.7007C2.22402 18.3651 -1.03666 12.9389 0.299432 7.58202C1.63458 2.22424 7.06113 -1.03637 12.418 0.299247C17.7761 1.63487 21.0365 7.06171 19.7007 12.4192H19.701Z"
      fill="#EEB662"
    />
    <path
      d="M12.6658 14.3996L10.2894 11.4369L8.13338 14.3996H5.64682L9.10912 9.96179L5.59961 5.59961H8.13338L10.5098 8.54975L12.6501 5.59961H15.1367L11.6901 10.0248L15.1996 14.3996H12.6658Z"
      fill="white"
    />
  </svg>
);

export const USDTIcon = <img src={usdtRound} alt="" width={17} height={17} />;

export const XPLIconLG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="33"
    viewBox="0 0 34 33"
    fill="none"
  >
    <rect
      x="0.969727"
      width="32.2105"
      height="32.2105"
      rx="6.78873"
      fill="#F26BD4"
    />
    <path
      d="M19.7143 22.0682L16.7735 17.6684L14.1053 22.0682H11.0282L15.3128 15.4779L10.9697 9H14.1053L17.0461 13.381L19.6948 9H22.772L18.5068 15.5715L22.8499 22.0682H19.7143Z"
      fill="white"
    />
  </svg>
);

export const PUSDIconLG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
  >
    <rect width="32.2105" height="32.2105" rx="6.78873" fill="#6289EE" />
    <path
      d="M18.7446 22.0682L15.8038 17.6684L13.1356 22.0682H10.0584L14.3431 15.4779L10 9H13.1356L16.0764 13.381L18.7251 9H21.8023L17.5371 15.5715L21.8802 22.0682H18.7446Z"
      fill="white"
    />
  </svg>
);

export const AUSDIconLG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
  >
    <rect width="32.2105" height="32.2105" rx="6.78873" fill="#EEB662" />
    <path
      d="M18.7446 22.0682L15.8038 17.6684L13.1356 22.0682H10.0584L14.3431 15.4779L10 9H13.1356L16.0764 13.381L18.7251 9H21.8023L17.5371 15.5715L21.8802 22.0682H18.7446Z"
      fill="white"
    />
  </svg>
);

export const USDTIconLG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
  >
    <path
      d="M25.42 0.0302734H6.79C3.03999 0.0302734 0 3.07026 0 6.82027V25.4503C0 29.2003 3.03999 32.2403 6.79 32.2403H25.42C29.17 32.2403 32.21 29.2003 32.21 25.4503V6.82027C32.21 3.07026 29.17 0.0302734 25.42 0.0302734Z"
      fill="#53AE94"
    />
    <path
      d="M18.0269 13.0465V10.6509H23.6332V7H8.37516V10.6509H13.9815V13.0465C9.42426 13.2516 6 14.1294 6 15.1878C6 16.2461 9.42426 17.124 13.9815 17.3291V25H18.0352V17.3291C22.5841 17.124 26 16.2461 26 15.1878C26 14.1294 22.5841 13.2516 18.0352 13.0465M18.0269 16.6809C17.9094 16.6809 17.3219 16.722 16.021 16.722C14.9719 16.722 14.2417 16.6892 13.9815 16.6809C9.96139 16.5087 6.95678 15.8277 6.95678 15.0073C6.95678 14.1869 9.96139 13.5059 13.9815 13.3254V16C14.2417 16.0164 14.9971 16.0574 16.0378 16.0574C17.2883 16.0574 17.9178 16.0082 18.0269 16V13.3254C22.0386 13.4977 25.0432 14.1869 25.0432 14.9991C25.0432 15.8113 22.047 16.5005 18.0269 16.6727"
      fill="white"
    />
  </svg>
);

const CurrencyIcons = () => {
  return <div>CurrencyIcons</div>;
};

export default CurrencyIcons;
