import { LayoutImage1, LayoutImage2, LayoutImage3 } from '../images/Auth';
import { PLATFORM_NAME } from '../utils/constants';

export const sliderData = [
  {
    image: LayoutImage1,
    title: 'Single Sign-on is an authentication',
    text: 'Scheme that allows a user to log in with a single ID to any of several related, yet independent, software systems',
  },
  {
    image: LayoutImage2,
    title: 'Secure Your Digital Identity',
    text: `With ${PLATFORM_NAME}, Take Full Control Of Your Personal Data In A Secure And Transparent Way.`,
  },
  {
    image: LayoutImage3,
    title: 'Seamless Integration',
    text: 'Log In Once And Access A Universe Of Services Without Compromising On Security.',
  },
  {
    image: LayoutImage2,
    title: 'Be The Architect Of Your Online Identity',
    text: 'Customize, Manage, And Authenticate Your Digital Identity Like Never Before.',
  },
];
