import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IDrawer {
  drawer: {
    drawer: boolean;
    subMenu: any | null;
  };
  activeSlider: number;
}

const initialState = {
  // drawer: true,
  drawer: localStorage.getItem('drawer')
    ? localStorage.getItem('drawer') === 'true'
      ? true
      : false
    : false,
  subMenu: null,
  activeSlider: 0,
};

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    toggleDrawer(state, action: PayloadAction<any>) {
      state.drawer = action.payload;

      return state;
    },
    togglSubeMenu(state, action: PayloadAction<any>) {
      state.subMenu = action.payload;

      return state;
    },
    setActiveSlider: (state, action: PayloadAction<any>) => {
      return { ...state, activeSlider: action.payload };
    },
  },
});

export const { toggleDrawer, togglSubeMenu, setActiveSlider } =
  drawerSlice.actions;
export const selectDrawerSlice = (state: any) => state.drawer;
export const selectDrawer = (state: IDrawer) => state.drawer.drawer;
export const selectSubMenu = (state: IDrawer) => state.drawer.subMenu;

export default drawerSlice.reducer;
