import React from 'react';
import CardLayout from '../../../../../components/Cards/CardLayout';
import { airStreamMachine } from '../../../../../images/cloudk';
import SimpleSlider from '../../../../../components/Slider/CardSlider';
import { Navigation } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Spinner } from '../../../../../SVGIcons/spinner.svg';
import { selectCloudSlice } from '../../../../../store/cloudk/cloudkSlice';
import { useSelector } from 'react-redux';

const data = [
  {
    image: airStreamMachine,
    name: 'Homnifi AirStream',
  },
  {
    image: airStreamMachine,
    name: 'Homnifi AirStream2',
  },
  {
    image: airStreamMachine,
    name: 'Homnifi AirStream3',
  },
  {
    image: airStreamMachine,
    name: 'Homnifi AirStream4',
  },
  {
    image: airStreamMachine,
    name: 'Homnifi AirStream5',
  },
];
const TopMachines = () => {
  const navigate = useNavigate();
  const { upgradeAbleMachinesLoading, upgradeAblesMachinesData } = useSelector(selectCloudSlice)

  return (
    <div 
      style={{
        background:  "linear-gradient(93.11deg, #FAE140 1.51%, #F2C462 27.11%, #EB9A0E 52.32%, #F2C462 73.67%, #FFE668 101.91%)" ,
      }}
      className="rounded-xl  p-[2px] md:col-span-6 xl:col-span-3 xl:h-auto h-[185px]  col-span-12 !shadow-boxShadow">
      <CardLayout className=" bg-white h-full  flex flex-col justify-between gap-2 ">
        {upgradeAbleMachinesLoading ? <div className="flex justify-center items-center flex-1  h-full w-full">
          <div className="justify-center items-center self-center">
            <div className="flex flex-col gap-5">
              <Spinner className="animate-spin h-6" />
            </div>
          </div>
        </div> : <section className="flex flex-col gap-4 cloudkSlider relative h-full">
          <SimpleSlider
            //   className={`w-full mt-2  min-h-[320px] !mx-auto ${loading ? '' : ' xxs:!py-7'} `}
            className="w-full h-full topMachineSlider"
            loop={true}
            navigation={true}
            //   pagination={{
            //       el: '.wallet-swiper-pagination-custom',
            //       clickable: true,
            //       type: 'bullets',
            //   }}
            modules={[Navigation]}
            //   onSwiper={handleSlideChange}
            //   onSlideChange={handleSlideChange}
            //   initialSlide={activeSlideIndex}
            spaceBetween={20}
            slidesPerView={1}
          >
            {data?.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="px-5 py-3 relative h-full">
                  <div
                    style={{
                      background: `linear-gradient(93.11deg, rgba(250, 225, 64, 0.3) 1.51%, rgba(242, 196, 98, 0.3) 27.11%, rgba(235, 154, 14, 0.3) 52.32%, rgba(242, 196, 98, 0.3) 73.67%, rgba(255, 230, 104, 0.3) 101.91%)`
                    }}
                    className="w-44 h-44 -top-2 right-5 rounded-full blur-[35px] z-[-10]  absolute "></div>

                  <div className="flex width justify-end px-4 relative h-full items-start screen370:items-center xl:items-start screen1780:items-center pr-4 ">
                    <div className="w-36 h-28 relative">
                      <img
                        src={item.image}
                        alt={item.name}
                        className="h-full w-full z-[999] relative "
                      />
                    </div>
                  </div>

                  <div className=" flex flex-col gap-2 screen370:gap-3 absolute bottom-2 xl:bottom-2 xl:gap-2  screen370:bottom-4
                  screen1780:gap-3 screen1780:bottom-4
                  left-6">
                    <span className="text-sm font-semibold text-[#121212]">
                      {item.name}
                    </span>
                    <div
                      style={{
                        boxShadow: '0px 4.1px 18.56px 0px rgba(235, 154, 14, 0.5)',
                        background: "linear-gradient(93.11deg, #FAE140 1.51%, #F2C462 27.11%, #EB9A0E 52.32%, #F2C462 73.67%, #FFE668 101.91%)",
                      }}

                      className="cursor-pointer shadow-lg w-32 p-2 h-6 z-20 rounded-xl  flex justify-center items-center "
                    >
                      <span
                        className=" font-medium text-white  text-xs"
                        onClick={() =>
                          navigate('/defi-360/cloudk/software-upgrade')
                        }
                      >
                        Upgrade Software
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </SimpleSlider>
        </section>}
        
      </CardLayout>
    </div>
  );
};

export default TopMachines;
