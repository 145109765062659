/* eslint-disable no-lone-blocks */
import moment from 'moment';
import React, { memo, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ReactComponent as ArrowDownIcon } from '../../SVGIcons/arrowDown.svg';
import OutsideClickHandler from '../../components/Global/OutsideClickHandler';

const RangeDatePicker = ({
  handleDateDate,
  resetFilter = false,
  toDateClassName,
  fromDateClassName,
  dateArrowAllow = false,
  isFutureAllowed = false,
  fromDateDisable = false,
  toDateDisable = false,
  setDateChanged,
}: {
  handleDateDate?: (start: any, end: any) => void;
  resetFilter?: boolean;
  toDateClassName?: string;
  fromDateClassName?: string;
  dateArrowAllow?: boolean;
  isFutureAllowed?: boolean;
  fromDateDisable?: boolean;
  toDateDisable?: boolean;
  setDateChanged?: (value: React.SetStateAction<boolean>) => void;
}) => {
  const [endDate, setEndDate] = React.useState<any>(null);
  const [isFromOpen, setIsFromOpen] = React.useState(false);
  const [isToOpen, setIsToOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState<any>(null);

  // start data handler
  const handleStartDateChange = (date: any) => {
    setStartDate(date);
    setIsFromOpen(false);
    setDateChanged && setDateChanged(true);
    if (endDate) {
      {
        handleDateDate && handleDateDate(date, endDate);
      }
    }
  };

  const handleEndDateChange = (endDate: any) => {
    setEndDate(endDate);
    setIsToOpen(false);
    setDateChanged && setDateChanged(true);
    if (startDate) {
      {
        handleDateDate && handleDateDate(startDate, endDate);
      }
    }
  };

  const today = new Date();
  useEffect(() => {
    if (resetFilter) {
      setEndDate(null);
      setIsFromOpen(false);
      setIsToOpen(false);
      setStartDate(null);
    }
  }, [resetFilter]);

  return (
    <div className="flex xs:flex-row  flex-col md:!gap-4 xxs:gap-3">
      {/* from date  */}
      <div className="flex flex-col gap-2">
        <OutsideClickHandler
          onOutsideClick={() => {
            setIsFromOpen(false);
          }}
        >
          <div className="flex flex-col gap-2 items-center relative">
            <div className="flex flex-col">
              <input
                className={`h-10 border text-xs font-normal text-gray-400  px-4 rounded-lg w-full min-w-44 focus:outline-none border-cardBorder bg-white ${fromDateClassName} `}
                type="text"
                name="text"
                value={
                  startDate
                    ? `${moment(startDate).format('DD/MM/YYYY')}`
                    : 'From date'
                }
                readOnly
                onFocus={() => {
                  setIsFromOpen(!isFromOpen);
                }}
                // value={formik.values.startDateTime}
                disabled={fromDateDisable}
                placeholder="Select from date"
              />
              {dateArrowAllow && (
                <button
                  className="absolute top-3 right-4"
                  onClick={() => setIsFromOpen(!isFromOpen)}
                >
                  <ArrowDownIcon
                    className={`w-3 h-3 transform ${
                      isFromOpen ? 'rotate-180' : 'rotate-0'
                    } transition-transform duration-300`}
                  />
                </button>
              )}
            </div>
            {isFromOpen && (
              <div className="mt-1 absolute top-[38px] z-[999] left-0">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  selectsStart
                  startDate={startDate}
                  placeholderText="Select from date"
                  maxDate={
                    isFutureAllowed
                      ? new Date(
                          new Date().setFullYear(new Date().getFullYear() + 2)
                        )
                      : endDate
                        ? endDate
                        : today
                  }
                  inline
                />
              </div>
            )}
          </div>
        </OutsideClickHandler>
      </div>

      <div className="flex flex-col gap-2">
        <OutsideClickHandler
          onOutsideClick={() => {
            setIsToOpen(false);
          }}
        >
          <div className="flex flex-col gap-4 items-center relative">
            <div className="flex flex-col">
              {/* <label className="text-sm flex font-medium text-primary">
                To Date:{' '}
              </label> */}
              <input
                className={`h-10 border text-xs font-normal text-gray-400 px-4 rounded-lg w-full min-w-44 focus:outline-none bg-white ${toDateClassName}`}
                type="text"
                name="text"
                onFocus={() => {
                  setIsToOpen(!isToOpen);
                }}
                readOnly
                value={
                  endDate
                    ? `${moment(endDate).format('DD/MM/YYYY')}`
                    : 'To date'
                }
                // value={moment(endDate).format('l')}
                // value={formik.values.startDateTime}
                placeholder="Select to date"
                disabled={toDateDisable}
              />
              {dateArrowAllow && (
                <button
                  className="absolute top-3 right-4"
                  onClick={() => setIsToOpen(!isToOpen)}
                >
                  <ArrowDownIcon
                    className={`w-3 h-3 transform ${
                      isToOpen ? 'rotate-180' : 'rotate-0'
                    } transition-transform duration-300`}
                  />
                </button>
              )}
            </div>
            {isToOpen && (
              <div className="absolute top-[42px] z-[999] left-0">
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  maxDate={today}
                  placeholderText="Select to date"
                  inline
                />
              </div>
            )}
          </div>
        </OutsideClickHandler>
      </div>
    </div>
  );
};

export default memo(RangeDatePicker);
