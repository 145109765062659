
import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';
import { setCloudkSlice } from './cloudkSlice';
import ApiRequestCloudk from '../../utils/network/ApiRequestCloudk';
import {
  ADD_MINTING_TOKEN,
  ALL_DETAILS,
  ALL_HARDWARES,
  ALL_LICENSES,
  CLAIM_HARDWARE_GENERATED_REWARD,
  CLAIMABLE_REWARD_AUTOLINK,
  CLAIMABLE_REWARD_CLOUDK,
  CLOUDK_TRANSACTION_HISTORY,
  CONNECT_NEW_MINTER,
  DE_LINK_HARDWARE,
  MACHINE_REWARD_HISTORY,
  REWARD_ANALYTICS_GRAPH_API,
  TRANSACTION_HISTORY_LICENSE_FILTER,
  USER_LICENSE_ACTIVE_COUNT,
  USER_MINTER_DETAIL,
  USER_MINTER_LINKED_LICENSE,
} from '../../utils/network/ApiEndpoints';
import { AxiosResponse } from 'axios';

export const useCloudkActions = () => {
  const dispatch = useDispatch();
  const cloudKClaimableReward = async () => {
    dispatch(
      setCloudkSlice({
        claimableRewardLoading: true,
      })
    );
    toast.success('Reward claimed successfully!');

    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: CLAIMABLE_REWARD_CLOUDK,
      })
      .then((response) => {
        dispatch(
          setCloudkSlice({
            // userRewards: response.data.data,
            claimableRewardLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setCloudkSlice({
            claimableRewardLoading: false,
          })
        );

        if (error.data) {
          // toast.error(error.data.message);
        }

        return error;
      });
  };

  const rewardAnalyticsGraphDataFunc = async (
    tab: string,
    key: string,
    userId: string | null
  ) => {
    dispatch(
      setCloudkSlice({
        rewardAnalyticsGraphLoading: true,
      })
    );

    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: `${REWARD_ANALYTICS_GRAPH_API}`,
        params: {
          tab: tab,
          timeline: key,
          id: userId,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setCloudkSlice({
            rewardAnalyticsGraphData: data,
            rewardAnalyticsGraphLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setCloudkSlice({
            rewardAnalyticsGraphLoading: false,
          })
        );
        if (error.data) {
          // toast.error(error.data.message);
        }
        return error;
      });
  };

  const getAllLicenses = async () => {
    dispatch(
      setCloudkSlice({
        allLicienceLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: ALL_LICENSES,
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            allLiciencesData: response.data.data,
            allLicienceLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            allLicienceLoading: false,
          })
        );
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const getAllDetails = async () => {
    dispatch(
      setCloudkSlice({
        upgradeAbleMachinesLoading: true,
        detailDataLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: ALL_DETAILS,
      })
      .then((response: AxiosResponse) => {
        dispatch(
          setCloudkSlice({
            upgradeAblesMachinesData: response.data.data,
            upgradeAbleMachinesLoading: false,
            detailDataLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            upgradeAbleMachinesLoading: false,
            detailDataLoading: false,
          })
        );
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };
  const getAllHardwares = async () => {
    dispatch(
      setCloudkSlice({
        allHardwareLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: ALL_HARDWARES,
      })
      .then((response: AxiosResponse) => {
              // console.log('response hardware', response);

        dispatch(
          setCloudkSlice({
            allHArdwaresData: response.data.data,
            allHardwareLoading: false,
          })
        );
        return response;
      })
      .catch((error: any) => {
        dispatch(
          setCloudkSlice({
            allHardwareLoading: false,
          })
        );
        toast.error(error?.data?.message || 'Something went wrong');
        return error;
      });
  };

      const getCloudKTransactionHistory = async ({
        page = 1,
        limit = 5,
        id,
        type,
        fromDate,
        toDate,
      }: any) => {
        dispatch( 
          setCloudkSlice({
            transactionHistoryLoading: true,
          })
        );
        return await ApiRequestCloudk()
          .request({
            method: 'GET',
            url: CLOUDK_TRANSACTION_HISTORY,
            params: {
              page,
              limit,
              userLicenseId:id,
              type,
              fromDate,
              toDate,
            },
          })
          .then((response) => {
            console.log('response is', response);
            const {
              data: { data },
            } = response;
            dispatch(
              setCloudkSlice({
                transactionHistoryData: data,
              })
            );

        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setCloudkSlice({
            transactionHistoryLoading: false,
          })
        );
      });
  };

  const getMachineRewardHistory = async ({
    page = 1,
    limit = 10,
    fromDate,
    toDate,
    query,
    type,
  }: any) => {
    dispatch(
      setCloudkSlice({
        machineRewardHistoryLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: MACHINE_REWARD_HISTORY,
        params: {
          query,
          page,
          limit,
          fromDate,
          toDate,
          type,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setCloudkSlice({
            machineRewardHistoryData: data,
          })
        );

        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setCloudkSlice({
            machineRewardHistoryLoading: false,
          })
        );
      });
  };

  const addMintingTokenFunc = async (data: any) => {
    dispatch(setCloudkSlice({ createMintingTokenLoading: true }));
    return await ApiRequestCloudk()
      .request({
        method: 'POST',
        url: ADD_MINTING_TOKEN,
        data,
      })
      .then((response: any) => {
        dispatch(setCloudkSlice({ createMintingTokenLoading: false }));

        getAllLicenses();
        return response;
      })
      .catch((error: any) => {
        dispatch(setCloudkSlice({ createMintingTokenLoading: false }));
        return error;
      });
  };

  const connectNewMinterFunc = async (data: any) => {
    dispatch(setCloudkSlice({ connectNewMinterLoading: true }));
    return await ApiRequestCloudk()
      .request({
        method: 'POST',
        url: CONNECT_NEW_MINTER,
        data,
      })
      .then((response: any) => {
        dispatch(setCloudkSlice({ connectNewMinterLoading: false }));

        getAllHardwares();
        return response;
      })
      .catch((error: any) => {
        dispatch(setCloudkSlice({ connectNewMinterLoading: false }));
        return error;
      });
  };

  const getDailyMinterActivityFunc = async ({
    month = 1,
    year = 2024,
  }: any) => {
    dispatch(
      setCloudkSlice({
        dailyActivityLoading: true,
      })
    );
    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: MACHINE_REWARD_HISTORY,
        params: {
          month,
          year,
        },
      })
      .then((response) => {
        const {
          data: { data },
        } = response;
        dispatch(
          setCloudkSlice({
            dailyActivityData: data,
          })
        );

        return response;
      })
      .catch((error) => {
        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      })
      .finally(() => {
        dispatch(
          setCloudkSlice({
            dailyActivityLoading: false,
          })
        );
      });
  };

  const deLinkLicenseFunc = async (data: any) => {
    dispatch(setCloudkSlice({ deLinkLoading: true }));
    return await ApiRequestCloudk()
      .request({
        method: 'POST',
        url: DE_LINK_HARDWARE,
        data,
      })
      .then((response: any) => {
        dispatch(setCloudkSlice({ deLinkLoading: false }));

        // getAllLicenses();
        return response;
      })
      .catch((error: any) => {
        dispatch(setCloudkSlice({ deLinkLoading: false }));
        return error;
      });
  };

  const hardwareGeneratedRewardClaimfunc = async () => {
    dispatch(
      setCloudkSlice({
        hardwareClaimLoading: true,
      })
    );
    toast.success('Reward claimed successfully!');

    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: CLAIM_HARDWARE_GENERATED_REWARD,
      })
      .then((response) => {
        dispatch(
          setCloudkSlice({
            // userRewards: response.data.data,
            hardwareClaimLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setCloudkSlice({
            hardwareClaimLoading: false,
          })
        );

        if (error.data) {
          // toast.error(error.data.message);
        }

        return error;
      });
  };
  const cloudKClaimableRewardAutoLinkFunc = async () => {
    dispatch(
      setCloudkSlice({
        claimableRewardAutoLinkLoading: true,
      })
    );

    return await ApiRequestCloudk()
      .request({
        method: 'GET',
        url: CLAIMABLE_REWARD_AUTOLINK,
      })
      .then((response) => {
        dispatch(
          setCloudkSlice({
            isGenerationSwitch: response.data.data,
            claimableRewardAutoLinkLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setCloudkSlice({
            claimableRewardAutoLinkLoading: false,
          })
        );

        if (error.data) {
          // toast.error(error.data.message);
        }

       return error;
     });
 };
           const getAllLicienceIDsFunc = async () => {
            //  dispatch(
            //    setCloudkSlice({
            //      allLicienceLoading: true,
            //    })
            //  );
             return await ApiRequestCloudk()
               .request({
                 method: 'GET',
                 url: TRANSACTION_HISTORY_LICENSE_FILTER,
               })
               .then((response: AxiosResponse) => {
                console.log({response})
                 dispatch(
                   setCloudkSlice({
                     allLiciencesIds: response.data.data.licensesList,
                     allLiciencesStatus: response.data.data.transactionType,
                     //  allLicienceLoading: false,
                   })
                 );
                 return response;
               })
               .catch((error: any) => {
                 //  dispatch(
                 //    setCloudkSlice({
                 //      allLicienceLoading: false,
                 //    })
                 //  );
                 toast.error(error?.data?.message || 'Something went wrong');
                 return error;
               });
           };

            const getActiveLicensesCountFunc = async () => {
              //  dispatch(
              //    setCloudkSlice({
              //      allLicienceLoading: true,
              //    })
              //  );
              return await ApiRequestCloudk()
                .request({
                  method: 'GET',
                  url: USER_LICENSE_ACTIVE_COUNT,
                })
                .then((response: AxiosResponse) => {
                  console.log('active count' ,{ response });
                  dispatch(
                    setCloudkSlice({
                      // allLiciencesIds: response.data.data.list,
                      // allLiciencesStatus: response.data.data.status,
                      //  allLicienceLoading: false,
                      activeLicenses: response.data.data[0].Activecount,
                      inActiveLicenses: response.data.data[0].InactiveCount,
                      totalLicenses: response.data.data[0].Activecount + response.data.data[0].InactiveCount,
                      
                    })
                  );
                  return response;
                })
                .catch((error: any) => {
                  //  dispatch(
                  //    setCloudkSlice({
                  //      allLicienceLoading: false,
                  //    })
                  //  );
                  toast.error(error?.data?.message || 'Something went wrong');
                  return error;
                });
            };
            // const getAllLicensesStatusFunc = async () => {
            //   // dispatch(
            //   //   setCloudkSlice({
            //   //     allLicienceLoading: true,
            //   //   })
            //   // );
            //   return await ApiRequestCloudk()
            //     .request({
            //       method: 'GET',
            //       url: TRANSACTION_HISTORY_LICENSE_STATUS_FILTER,
            //     })
            //     .then((response: AxiosResponse) => {
            //       dispatch(
            //         setCloudkSlice({
            //           allLiciencesStatus: response.data.data,
            //           // allLicienceLoading: false,
            //         })
            //       );
            //       return response;
            //     })
            //     .catch((error: any) => {
            //       // dispatch(
            //       //   setCloudkSlice({
            //       //     allLicienceLoading: false,
            //       //   })
            //       // );
            //       toast.error(error?.data?.message || 'Something went wrong');
            //       return error;
            //     });
            // };
              const getUserMinterDetailFunc = async ({
                id
              }:any) => {
                //  dispatch(
                //    setCloudkSlice({
                //      allLicienceLoading: true,
                //    })
                //  );
                console.log('detail ++' , id)
                return await ApiRequestCloudk()
                  .request({
                    method: 'GET',
                    url: USER_MINTER_DETAIL,
                    params:{
                      id
                    }
                  })
                  .then((response: AxiosResponse) => {
                    console.log('user minter detail', { response });
                    dispatch(
                      setCloudkSlice({
                        minterRewardGeneratedData: response.data.data[0],
                        // activeLicenses: response.data.data[0].Activecount,
                        // inActiveLicenses: response.data.data[0].InactiveCount,
                        // totalLicenses:
                        //   response.data.data[0].Activecount +
                        //   response.data.data[0].InactiveCount,
                      })
                    );
                    return response;
                  })
                  .catch((error: any) => {
                    //  dispatch(
                    //    setCloudkSlice({
                    //      allLicienceLoading: false,
                    //    })
                    //  );
                    toast.error(error?.data?.message || 'Something went wrong');
                    return error;
                  });
              };
               const getUserMinterLinkedLicenses = async ({
                 page = 1 ,
                 limit = 10 ,
                 id,           
               }: any) => {

                console.log('linked+++', page , limit,id);
                 //  dispatch(
                 //    setCloudkSlice({
                 //      allLicienceLoading: true,
                 //    })
                 //  );
                 return await ApiRequestCloudk()
                   .request({
                     method: 'GET',
                     url: USER_MINTER_LINKED_LICENSE,
                     params: {
                       page: page,
                       limit: limit,
                       id,
                     },
                   })
                   .then((response: AxiosResponse) => {
                     console.log('user minter linked licenses', { response });
                     dispatch(
                       setCloudkSlice({
                          minterLinkedLicenses:response.data.data
                         // activeLicenses: response.data.data[0].Activecount,
                         // inActiveLicenses: response.data.data[0].InactiveCount,
                         // totalLicenses:
                         //   response.data.data[0].Activecount +
                         //   response.data.data[0].InactiveCount,
                       })
                     );
                     return response;
                   })
                   .catch((error: any) => {
                     //  dispatch(
                     //    setCloudkSlice({
                     //      allLicienceLoading: false,
                     //    })
                     //  );
                     toast.error(
                       error?.data?.message || 'Something went wrong'
                     );
                     return error;
                   });
               };
  return {
    cloudKClaimableReward,
    rewardAnalyticsGraphDataFunc,
    getAllLicenses,
    getAllHardwares,
    getCloudKTransactionHistory,
    getMachineRewardHistory,
    getAllDetails,
    addMintingTokenFunc,
    connectNewMinterFunc,
    getDailyMinterActivityFunc,
    deLinkLicenseFunc,
    hardwareGeneratedRewardClaimfunc,
    cloudKClaimableRewardAutoLinkFunc,
    getAllLicienceIDsFunc,
    // getAllLicensesStatusFunc,
    getActiveLicensesCountFunc,
    getUserMinterDetailFunc,
    getUserMinterLinkedLicenses,
  };
};
