//REDUX-TOOLKIT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TOKEN_ROUND_ICONS } from '../../utils/allConstants';

// enum WITHDRAW_TABS {
//   EXTERNAL = 'External Address',
//   INTERNAL = 'Homnifi User ID',
// }

// export const withdraw_tabs = {
//   [WITHDRAW_TABS.EXTERNAL]: 'External Address',
//   [WITHDRAW_TABS.INTERNAL]: 'Homnifi User ID',
// };

// export const tabsOptions = [
//   withdraw_tabs[WITHDRAW_TABS.EXTERNAL],
//   withdraw_tabs[WITHDRAW_TABS.INTERNAL],
// ];

export interface StateSpecialSwap {
  walletList: TOKEN[];
  selectedFromWallet: TOKEN | null;
  selectedToWallet: TOKEN | null;
  swapAmount: number | null;
  convertedAmount: number | null;
  swapAmountPercentage: number;
  currentStep: 1 | 2 | 3;
  currentSwapBalance: number;
}

export interface TOKEN {
  name: string;
  value: string;
  icon: any;
}

export const TOKEN_LYK_D: TOKEN = {
  name: 'LYK - D',
  value: 'LYK - D',
  icon: TOKEN_ROUND_ICONS['USDT'],
};

export const SPECIAL_SWAP_SLICE_NAME = 'special_swap';

const initialState: StateSpecialSwap = {
  walletList: [
    TOKEN_LYK_D,
    { name: 'LYK - W', value: 'LYK - W', icon: TOKEN_ROUND_ICONS['USDT'] },
    { name: 'LYK - R', value: 'LYK - R', icon: TOKEN_ROUND_ICONS['USDT'] },
    { name: 'sLYK', value: 'sLYK', icon: TOKEN_ROUND_ICONS['USDT'] },
  ],
  selectedFromWallet: null,
  selectedToWallet: null,
  swapAmount: null,
  convertedAmount: null,
  swapAmountPercentage: 0,
  currentStep: 1,
  currentSwapBalance: 0,
};

const slice = createSlice({
  name: SPECIAL_SWAP_SLICE_NAME,
  initialState,
  reducers: {
    setSpecialSwapSlice: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const specialSwapSliceAction = slice.actions;
export default slice.reducer;
