import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  clearStorage,
  getAccessTokenFromCookie,
  removeAccessTokenCookie,
  removeRefreshTokenCookie,
  removeUserCookie,
  setStorage,
  setLoginTokenCookie,
  removeLoginTokenCookie,
} from '../../utils/storage/protectedStorage';
import {
  TReferrals,
  TUser,
} from '../../components/interface/auth/auth.interface';
import { ACCESS_TOKEN, LOGIN_TOKEN } from '../../utils/constants';
import { TABS_KEYS } from '../../components/Profile/Tabs';

interface UserSliceState {
  accessToken: string;
  loginToken: string;
  loginPopup: boolean;
  user: TUser | null;
  referrals: TReferrals | null;
  profileActiveTab: string;
  onboardingSteps: any;
  shopPurchased: any;
  loginLoading: boolean;
  signupLoading: boolean;
  profileLoading: boolean;
  userEmailLoading: boolean;
  retryKycLoading: boolean;
  verifyOtpLoading: boolean;
  accountTypeLoading: boolean;
  termsAcceptLoading: boolean;
  getTOtpLoading: boolean;
  getUserLoading: boolean;
  submitReferralLoading: boolean;
  changePasswordConfirmLoading: boolean;
  addressLoading: boolean;
  userNameLoading: boolean;
  resetPasswordLoading: boolean;
  changePasswordLoading: boolean;
  kycStartLoading: boolean;
  recentDevices: any[];
  userProfilePercentage: number;
  profileComplete: boolean;
  profileCompletePercentage: number;
  isProfileCompletePercentage: boolean;
  resendOtpLoading: boolean;
  recentDevicesLoading?: boolean;
  referralsLoading?: boolean;
  referralsUplineLoading?: boolean;
  isImpersonateMode?: boolean;
  builderGeneral?: boolean;
  builderReferral?: boolean;
  baseReferral?: boolean;
}

const initialState: UserSliceState = {
  accessToken: getAccessTokenFromCookie() || '',
  user: null,
  loginToken: '',
  onboardingSteps: null,
  shopPurchased: null,
  referrals: null,
  profileActiveTab: TABS_KEYS.PROFILE,
  signupLoading: false,
  loginPopup: false,
  loginLoading: false,
  profileLoading: false,
  retryKycLoading: false,
  verifyOtpLoading: false,
  accountTypeLoading: false,
  termsAcceptLoading: false,
  getTOtpLoading: false,
  getUserLoading: false,
  submitReferralLoading: false,
  addressLoading: false,
  userNameLoading: false,
  resetPasswordLoading: false,
  changePasswordLoading: false,
  kycStartLoading: false,
  recentDevices: [],
  userEmailLoading: false,
  changePasswordConfirmLoading: false,
  userProfilePercentage: 0,
  profileComplete: false,
  resendOtpLoading: false,
  profileCompletePercentage: 10,
  isProfileCompletePercentage: false,
  builderGeneral: false,
  builderReferral: false,
  baseReferral: false,
};

const updateUserPercentage = (state: any) => {
  const user = state.user;
  let percentage = 10;
  if (user?.firstName || user?.lastName) {
    percentage = percentage + 30;
  }
  // if (user?.address && user?.country && user?.phone) {
  //   percentage = percentage + 20;
  // }
  if (user?.isActiveMembership) {
    percentage = percentage + 30;
  }
  if (user?.kycApproved) {
    percentage = percentage + 30;
  }

  return percentage;
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLogin: (state, action: PayloadAction<any>) => {
      setStorage(ACCESS_TOKEN, action.payload[ACCESS_TOKEN]);
      setStorage(LOGIN_TOKEN, action.payload[LOGIN_TOKEN]);
      setLoginTokenCookie(action.payload[LOGIN_TOKEN]);
      return {
        ...state,
        accessToken: action.payload.accessToken,
        loginToken: action.payload.loginToken,
      };
    },
    reset: () => initialState,
    setLoginPopup: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        loginPopup: action.payload,
      };
    },
    setLogout: (state) => {
      removeAccessTokenCookie();
      removeRefreshTokenCookie();
      removeLoginTokenCookie();
      removeUserCookie();
      clearStorage();

      localStorage.removeItem('valuesOfRememberMe');

      return {
        ...state,
        accessToken: '',
        refreshToken: '',
        loginToken: '',
        user: null,
      };
    },
    setUserSlice: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    checkPercentage: (state) => {
      const percentage = updateUserPercentage(state);
      return {
        ...state,
        profileCompletePercentage: percentage,
      };
    },
    setUser: (state, action: PayloadAction<any>) => {
      const percentage = updateUserPercentage(state);
      return {
        ...state,
        user: {
          isImpersonateMode: state?.user?.isImpersonateMode ?? false,
          ...action.payload,
        },
        profileCompletePercentage: percentage,
      };
    },
    updateUser: (state, action: PayloadAction<any>) => {
      return {
        ...state,

        user: {
          ...state.user,
          ...action.payload,
        },
      };
    },
    setUserState: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setOnboardingSteps: (state, action: PayloadAction<any>) => {
      return { ...state, onboardingSteps: action.payload };
    },
    setShopPurchased: (state, action: PayloadAction<any>) => {
      return { ...state, shopPurchased: action.payload };
    },
    callUserInformation: (state) => {
      return { ...state, profileLoading: true };
    },
    updateLoading: (state, action: PayloadAction<any>) => {
      return { ...state, [action.payload.key]: action.payload.value };
    },
    setLoginToken: (state, action: PayloadAction<any>) => {
      setLoginTokenCookie(action.payload.loginToken);
      return {
        ...state,
        loginToken: action.payload.token,
        verifyOtpLoading: false,
        user: action.payload.user,
      };
    },
    emailUpdate: (state, action: PayloadAction<any>) => {
      const user = state.user ? { ...state.user, email: action.payload } : null;
      return {
        ...state,
        profileLoading: true,
        user,
      };
    },
    setResetPasswordLoading: (state, action: PayloadAction<any>) => {
      return { ...state, resetPasswordLoading: action.payload };
    },
    setProfileCompletePercentage: (state, action: PayloadAction<any>) => {
      if (state.profileCompletePercentage >= 100) {
        return state;
      }
      return {
        ...state,
        profileCompletePercentage:
          state.profileCompletePercentage + action.payload,
      };
    },

    setResendOtpLoading: (state, action: PayloadAction<any>) => {
      return { ...state, resendOtpLoading: action.payload };
    },
    setShowCommunity: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const {
  setLogin,
  setLogout,
  setUser,
  setOnboardingSteps,
  setShopPurchased,
  callUserInformation,
  updateLoading,
  setLoginToken,
  setUserState,
  emailUpdate,
  updateUser,
  setResetPasswordLoading,
  setUserSlice,
  reset,
  setProfileCompletePercentage,
  checkPercentage,
  setResendOtpLoading,
  setLoginPopup,
  setShowCommunity,
} = userSlice.actions;

export const selectUserSlice = (state: { user: UserSliceState }) => state.user;
export default userSlice.reducer;
