/* eslint-disable react-hooks/exhaustive-deps */
import { AnimatePresence, motion } from 'framer-motion';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNotificationsActions } from '../../store/notifications/notificationActions';
import { selectNotification } from '../../store/notifications/notificationsSlice';
import { ReactComponent as ChangeEmailIcon } from '../../SVGIcons/Notifications/changeEmail.svg';
import { ReactComponent as ChangePasswordIcon } from '../../SVGIcons/Notifications/changePassword.svg';
import { ReactComponent as CliamedRewardIcon } from '../../SVGIcons/Notifications/claimedReward.svg';
import { ReactComponent as DepositIcon } from '../../SVGIcons/Notifications/deposit.svg';
import { ReactComponent as KycIcon } from '../../SVGIcons/Notifications/kyc.svg';
import { ReactComponent as ProfileIcon } from '../../SVGIcons/Notifications/profile.svg';
import { ReactComponent as ProfilePictureIcon } from '../../SVGIcons/Notifications/profilePicture.svg';
import { ReactComponent as RegisterationIcon } from '../../SVGIcons/Notifications/registeration.svg';
import { ReactComponent as RewardIcon } from '../../SVGIcons/Notifications/reward.svg';
import { ReactComponent as StackIcon } from '../../SVGIcons/Notifications/stake.svg';
import { ReactComponent as SwapIcon } from '../../SVGIcons/Notifications/swap.svg';
import { ReactComponent as TransactionIcon } from '../../SVGIcons/Notifications/transaction.svg';
import { ReactComponent as WithdrawIcon } from '../../SVGIcons/Notifications/withdraw.svg';
import { timeAgo } from '../../utils/utils';

const transactionTypeList = ['deposit', 'swap', 'withdrawal', 'transfer'];
type NotificationComponent = React.ReactNode;

export const notificationCode: Record<any, NotificationComponent> = {
  transaction: <TransactionIcon />,
  withdrawal: <WithdrawIcon />,
  deposit: <DepositIcon />,
  transfer: <TransactionIcon />,
  swap: <SwapIcon />,
  stake: <StackIcon />,
  'claimed-reward': <CliamedRewardIcon />,
  reward: <RewardIcon />,
  'device-logged-in': <ChangePasswordIcon />,
  registration: <RegisterationIcon />,
  'change-email': <ChangeEmailIcon />,
  'change-password': <ChangePasswordIcon />,
  'profile-image-upload': <ProfilePictureIcon />,
  'kyc-approved': <KycIcon />,
  'kyc-rejected': <KycIcon />,
  'profile-update': <ProfileIcon />,
};

const TabContent = ({ activeTab }: { activeTab: string }) => {
  const { readHomnifiNotifications } = useNotificationsActions();
  const {
    homnifiNotificationList,
    homnifiAllNotificationList,
    unReadNotificationCount,
  } = useSelector(selectNotification);

  const onReadNotificationHandler = async (id: string) => {
    await readHomnifiNotifications(id);
  };

  const filterNotification = useMemo(() => {
    let filterlist: any = [];

    if (activeTab === 'all') {
      filterlist = homnifiNotificationList;
    } else {
      filterlist = homnifiAllNotificationList.filter(
        (dt: any) => dt.type === activeTab
      );
    }
    return filterlist;
  }, [activeTab, homnifiNotificationList, unReadNotificationCount]);
  switch (activeTab) {
    case 'all':
      return (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
            className="flex flex-col"
            style={{ height: '100%', width: '100%' }}
          >
            <div className="w-full h-full">
              {filterNotification.length > 0 ? (
                filterNotification.map((itm: any, index: number) => {
                  return (
                    <div className="" key={index}>
                      {!itm.read ? (
                        <div
                          className="flex justify-between w-full gap-2 border-b border-borderLightGray p-4 bg-primary/10 cursor-pointer"
                          onClick={() => {
                            onReadNotificationHandler(itm._id);
                          }}
                        >
                          <div className="w-10 h-10 flex justify-center items-center">
                            {notificationCode[itm?.type] ?? <ProfileIcon />}{' '}
                          </div>
                          <div className="flex flex-col justify-center gap-2 w-full">
                            <div className="w-full px-2">{itm.message}</div>
                          </div>
                          <span className="flex flex-col gap-2 items-center text-xs text-primaryGrey  min-w-8">
                            {timeAgo(itm.createdAt)}
                            <div className="w-2 h-2 bg-primaryRed rounded-full" />
                          </span>
                        </div>
                      ) : (
                        <div className="flex justify-between w-full gap-2 border-b border-borderLightGray p-4 ">
                          <div className="w-10 h-10 flex justify-center items-center">
                            {notificationCode[itm?.type] ?? <ProfileIcon />}{' '}
                          </div>
                          <div className="flex flex-col justify-center gap-2 w-full">
                            <div className="w-full px-2">{itm.message}</div>
                          </div>
                          <span className="text-xs text-primaryGrey min-w-6">
                            {timeAgo(itm.createdAt)}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="w-full h-20 flex items-center justify-center">
                  No Notifications Found!
                </div>
              )}
            </div>
          </motion.div>
        </AnimatePresence>
      );
    case 'transaction':
      return (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ duration: 0.3 }}
            style={{ height: '100%', width: '100%' }}
          >
            <div className="w-full h-full">
              {homnifiNotificationList.filter((dt: any) =>
                transactionTypeList.includes(dt.type)
              ).length > 0 ? (
                homnifiNotificationList
                  .filter((dt: any) => transactionTypeList.includes(dt.type))
                  ?.map((itm: any, index: number) => {
                    return (
                      <div className="" key={index}>
                        {!itm.read ? (
                          <div
                            className="flex justify-between w-full gap-2 border-b border-borderLightGray p-4 bg-primary/10 cursor-pointer"
                            onClick={() => {
                              onReadNotificationHandler(itm._id);
                            }}
                          >
                            <div className="w-10 h-10 flex justify-center items-center">
                              {notificationCode[itm?.type] ?? <ProfileIcon />}{' '}
                            </div>
                            <div className="flex flex-col justify-center gap-2 w-full">
                              <div className="w-full px-2">{itm.message}</div>
                            </div>
                            <span className="flex flex-col gap-2 items-center text-xs text-primaryGrey  min-w-8">
                              {timeAgo(itm.createdAt)}
                              <div className="w-2 h-2 bg-primaryRed rounded-full" />
                            </span>
                          </div>
                        ) : (
                          <div className="flex justify-between w-full gap-2 border-b border-borderLightGray p-4 ">
                            <div className="w-10 h-10 flex justify-center items-center">
                              {notificationCode[itm?.type] ?? <ProfileIcon />}{' '}
                            </div>
                            <div className="flex flex-col justify-center gap-2 w-full">
                              <div className="w-full px-2">{itm.message}</div>
                            </div>
                            <span className="text-xs text-primaryGrey min-w-6">
                              {timeAgo(itm.createdAt)}
                            </span>
                          </div>
                        )}
                      </div>
                    );
                  })
              ) : (
                <div className="w-full h-20 flex items-center justify-center">
                  No Notifications Found!
                </div>
              )}
            </div>
          </motion.div>
        </AnimatePresence>
      );
    case 'coming-soon':
      return (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ duration: 0.3 }}
            style={{ height: '100%', width: '100%' }}
          >
            {' '}
            <div className="w-full h-full">
              {filterNotification.length > 0 ? (
                filterNotification.map((itm: any, index: number) => {
                  return (
                    <div>
                      {!itm.read ? (
                        <div
                          className="flex justify-between w-full gap-2 border-b border-borderLightGray p-4 bg-primary/10"
                          onClick={() => {
                            onReadNotificationHandler(itm._id);
                          }}
                        >
                          <div className="w-10 h-10">
                            {notificationCode[itm?.type] ?? <ProfileIcon />}{' '}
                          </div>
                          <div className="flex flex-col justify-center gap-2 w-full">
                            <div className="w-full px-2">{itm.message}</div>
                          </div>
                          <span className="flex flex-col gap-2 items-center text-xs text-primaryGrey  min-w-8">
                            {timeAgo(itm.createdAt)}
                            <div className="w-2 h-2 bg-primaryRed rounded-full" />
                          </span>
                        </div>
                      ) : (
                        <div className="flex justify-between w-full gap-2 border-b border-borderLightGray p-4 ">
                          <div className="w-10 h-10">
                            {notificationCode[itm?.type] ?? <ProfileIcon />}{' '}
                          </div>
                          <div className="flex flex-col justify-center gap-2 w-full">
                            <div className="w-full px-2">{itm.message}</div>
                          </div>
                          <span className="text-xs text-primaryGrey min-w-6">
                            {timeAgo(itm.createdAt)}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="w-full h-20 flex items-center justify-center">
                  No Notifications Found!
                </div>
              )}
            </div>
          </motion.div>
        </AnimatePresence>
      );
    default:
      return null;
  }
};

export default TabContent;
