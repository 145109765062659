import React, { useEffect, useState } from 'react'
import CloudKSelect from '../Common/CloudKSelect/CloudKSelect'
import CloudKTable from '../Common/CloudKTable/CloudKTable'
import { formatAlphaNumericDate } from '../../../../utils/utils';
import { useSelector } from 'react-redux';
import { selectCloudSlice } from '../../../../store/cloudk/cloudkSlice';



const RewardHistory = () => {
    const date = new Date();
    const rows = [
        {
            date: <p className="text-[#121212] text-xs text-center">
                {formatAlphaNumericDate(date)}
            </p>,
            licenseId: <p className="text-[#121212] text-xs text-center ">
                CLDX-01724923571144
            </p>,
            hardwareBenifit: <div className='flex flex-col gap-2 items-center w-full'>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-R</span>
                    <span className='text-xs font-medium text-[#7FC9FF]'>4562,124</span>
                </div>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-W</span>
                    <span className='text-xs font-medium text-[#7583FF]'>4562,124</span>
                </div>
            </div>,
            licenseReward: <div className='flex flex-col gap-2  items-center w-full'>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-R</span>
                    <span className='text-xs font-medium text-[#7FC9FF]'>4562,124</span>
                </div>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-W</span>
                    <span className='text-xs font-medium text-[#7583FF]'>4562,124</span>
                </div>
            </div>,
        },
        {

            date: (
                <p className="text-[#121212] text-xs text-center">
                    {formatAlphaNumericDate(date)}
                </p>
            ),
            licenseId: <p className="text-[#121212] text-xs text-center">
                CLDX-01724923571144
            </p>,
            hardwareBenifit: <div className='flex flex-col gap-2 items-center w-full'>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-R</span>
                    <span className='text-xs font-medium text-[#7FC9FF]'>4562,124</span>
                </div>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-W</span>
                    <span className='text-xs font-medium text-[#7583FF]'>4562,124</span>
                </div>
            </div>,
            licenseReward: <div className='flex flex-col gap-2 items-center w-full'>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-R</span>
                    <span className='text-xs font-medium text-[#7FC9FF]'>4562,124</span>
                </div>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-W</span>
                    <span className='text-xs font-medium text-[#7583FF]'>4562,124</span>
                </div>
            </div>,
        },
        {

            date: (
                <p className="text-[#121212] text-xs text-center">
                    {formatAlphaNumericDate(date)}
                </p>
            ),
            licenseId: <p className="text-[#121212] text-xs text-center">
                CLDX-01724923571144
            </p>,
            hardwareBenifit: <div className='flex flex-col gap-2 items-center w-full'>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-R</span>
                    <span className='text-xs font-medium text-[#7FC9FF]'>4562,124</span>
                </div>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-W</span>
                    <span className='text-xs font-medium text-[#7583FF]'>4562,124</span>
                </div>
            </div>,
            licenseReward: <div className='flex flex-col gap-2 items-center w-full'>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-R</span>
                    <span className='text-xs font-medium text-[#7FC9FF]'>4562,124</span>
                </div>
                <div className='flex gap-3'>
                    <span className='text-xs text-[#939393]'>LYK-W</span>
                    <span className='text-xs font-medium text-[#7583FF]'>4562,124</span>
                </div>
            </div>,
        },
    ];

    const {machineRewardHistoryLoading} = useSelector(selectCloudSlice)
    const [resetFilter, setResetFilter] = useState(false);
    const [filters, setFilters] = useState<any>({
        limit: 10,
        page: 1,
        type: null,
        time: null,
        
    });
    const [resetDisable, setResetDisable] = useState(true);

    useEffect(() => {
        const noData = !rows?.length;
        const hasFilters = filters.type || filters.time;

        // Disable if (no data and no filters) OR (data and no filters)

        setResetDisable((noData && !hasFilters) || (!noData && !hasFilters));
    }, [rows, filters]);

    useEffect(() => {
        setResetFilter(false);
        // getCloudKTransactionHistory({
        //     page: filters.page,
        //     limit: filters.limit,
        //     licienceId: filters?.licenseId?.value,
        //     transactionType: filters?.licensesStatus?.value
        // });

    }, [filters]);
    const handleTypeChange = (selectedOption: { label: string; value: string }) => {
        setFilters((prev: any) => ({
            ...prev,
            type: selectedOption,
        }));
    };
    const handleTimeChange = (selectedOption: { label: string; value: string }) => {
        setFilters((prev: any) => ({
            ...prev,
            time: selectedOption,
        }));
    };
    const columns = [
        { label: 'Date', key: 'date', classNames: '!text-center text-[#939393] font-medium' },
        { label: 'Linked License', key: 'licenseId', classNames: ' !text-center text-[#939393] font-medium' },
        { label: 'Hardware Benefit', key: 'hardwareBenifit', classNames: '!text-center text-[#939393] font-medium' },
        { label: 'License Rewards', key: 'licenseReward', classNames: '!text-center text-[#939393] font-medium' },
    ];
   

   
    const options = [
        { label: 'Option 1', value: '' },
        { label: 'Option 1', value: '1' },
        { label: 'Option 2', value: '2' },
        { label: 'Option 3', value: '3' },
    ];
    return (
    <div>
       <CloudKTable
                    rows={rows}
                    title={'Hardware Transaction History'}
                    columns={columns}
                    loading={machineRewardHistoryLoading}
                    filters={[
                        <CloudKSelect options={options} placeholder={'Transaction Type'}
                            onChange={handleTypeChange} // Handle the option change
                            selectedOption={filters.type}
                        />,
                        <CloudKSelect options={options} placeholder={'All Time'} 
                            onChange={handleTimeChange} // Handle the option change
                            selectedOption={filters.time}
                            />,
                        <button
                            className={`${resetDisable ? 'cursor-not-allowed text-primaryGrey' : 'text-primary'} text-xs font-medium h-10`}
                            type="button"
                            disabled={resetDisable}
                            onClick={() => {
                                setResetFilter(true);
                                setFilters({
                                    limit: 10,
                                    page: 1,
                                    licenseId: null,
                                    licensesStatus: null,
                                    fromDate: null,
                                    toDate: null,
                                });

                            }}
                        >
                            Reset
                        </button>
                    ]}
                    // paginationContainerStyle={'!justify-end'}
                    // navigationText={`View All Rewards History`}
                    // navigationStyle={``}
                    // navigationShow={true}
                    // navigationContainerStyle={``}
                    // footerNavigation={{
                    //     text:"View All Rewards History",
                    //     url:'/defi-360/cloudk/reward-history'
                    // }}
                />
    </div>
  )
}

export default RewardHistory
