export const tokenIcon1 = '/img/cloudk/tokenIcon1.png';
export const tokenIcon2 = '/img/cloudk/tokenIcon2.png';
export const licenseImg = '/img/cloudk/licenseImg.png';
export const upIcon = '/img/cloudk/chevrons-upIcon.png';
export const hardwareImg = '/img/cloudk/hardwareImg.png';
export const airStreamMachine = '/img/cloudk/airStreamMachine.png';
export const totalLicienceWaterMark = '/img/cloudk/totalLicienceWaterMark.png';
export const totalRewardWaterMark = '/img/cloudk/totalRewardWaterMark.png';
export const totalLinkedWaterMark = '/img/cloudk/totalLinkedWaterMark.png';

